@import "src/styles";

.screensaver {
    @extend %click-extend;
    @extend %flex-align-center;

    --screensaver-flavour-size: min(10rem, 20vmin);
    --screensaver-flavours-radius: min(30rem, 40vmin);
    --screensaver-reveal-duration: .75s;

    position: fixed;
    z-index: 20;
    overflow: clip;
    animation: fade-in var(--screensaver-reveal-duration) ease forwards;
    background-color: rgba(var(--c-background-rgb), .6);
    backdrop-filter: blur(10px);

    &__flavours {
        @include circle(calc(var(--screensaver-flavours-radius) * 2));

        position: relative;
        animation: scale-in var(--screensaver-reveal-duration) ease forwards,
            spin 5s linear infinite,
            pulse 2.5s var(--screensaver-reveal-duration) ease-in-out infinite alternate;
    }

    &__flavour {
        --screensaver-flavour-offset: calc(360deg / var(--screensaver-flavour-amount));
        --screensaver-flavour-angle-offset: calc(var(--screensaver-flavour-index) * var(--screensaver-flavour-offset));

        @include circle(var(--screensaver-flavour-size));

        position: absolute;
        left: calc(50% - var(--screensaver-flavour-size) / 2);
        top: calc(50% - var(--screensaver-flavour-size) / 2);
        translate: calc(cos(var(--screensaver-flavour-angle-offset)) * var(--screensaver-flavours-radius))
            calc(sin(var(--screensaver-flavour-angle-offset)) * var(--screensaver-flavours-radius) * -1);
    }

    &__flavour-image {
        animation: spin 5s linear infinite reverse,
            undo-pulse 2.5s var(--screensaver-reveal-duration) ease-in-out infinite alternate;
    }
}
