@import "src/styles";

.drop-language-file-zone {

    &--is-active &__dropzone {
        display: block;
    }

    &--is-active &__activate-button {
        display: none;
    }

    &--is-dragging-over &__dropzone {
        background-color: var(--c-white);
        outline-style: solid;
    }

    &__activate-button-wrapper {
        display: flex;
        justify-content: flex-end;
    }

    &__dropzone {
        display: none;
        margin-block-start: 2rem;
        width: 100%;
        height: 15rem;
        background-color: rgba(var(--c-white-rgb), .8);
        outline: 2px dashed var(--c-orange);
        outline-offset: -1rem;
    }
}
