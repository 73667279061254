.flavour-header-background {

    &__gradient-start {
        stop-color: rgba(var(--c-cyan-rgb), .1);
    }

    &__gradient-end {
        stop-color: rgba(var(--c-sky-rgb), 0.25);
    }

    &__svg-wave {
        stroke: rgba(var(--c-white-rgb), .5);
        stroke-width: 1px;
    }
}
