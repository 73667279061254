@import "src/styles";

.two-column-text-slice {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    @include for-size(tablet-landscape-up) {
        flex-direction: row;
        gap: 8.5rem;
    }

    &--is-mirrored {
        flex-direction: column-reverse;

        @include for-size(tablet-landscape-up) {
            flex-direction: row-reverse;
        }
    }

    &__title {
        width: 100%;
    }

    &__paragraph {
        width: 100%;
    }
}
