@import "src/styles";

.vacancy-overview-skeletons {

    &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
        list-style-type: none;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            gap: 1.5rem;
        }
    }

    &__icon {
        @include square(5rem);

        border-radius: var(--border-radius);
        background-color: var(--c-cyan);
    }

    &__text-wrapper {
        width: 100%;
    }

    &__link-button-wrapper {
        max-width: 28rem;
    }

    &__link-button {
        font-size: 1.25rem;
    }

    &__meta-wrapper {
        display: flex;
        gap: 1.5rem;
        width: 10rem;
        margin-block-start: 1.5rem;
    }

    &__meta-label {
        font-size: .75rem;
    }
}
