.prismic-page-skeletons {

    &__title {
        height: 4rem;
    }

    &__paragraph {
        margin-block-start: 5rem;

        & + & {
            margin-block-start: 3.75rem;
        }
    }

    &__line {

        & + & {
            margin-block-start: 1rem;
        }
    }
}
