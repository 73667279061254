@import "src/styles";

.footer {
    position: relative;
    z-index: 10;

    @include for-size(tablet-landscape-up) {
        padding-block: 1.75rem 3.5rem;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-block-start: 1.25rem;
        gap: 5rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            justify-content: stretch;
            align-items: stretch;
            gap: 1rem;
            margin-block-start: 1.5rem;
        }
    }

    &__sitemap-wrapper {
        display: flex;
        gap: 3rem;
        width: 100%;

        @include for-size(tablet-landscape-up) {
            gap: 12.5rem;
        }
    }

    &__social-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include for-size(tablet-landscape-up) {
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &__nav {
        width: 100%;

        @include for-size(tablet-landscape-up) {
            width: max-content;
        }
    }

    &__title {
        @extend %label;

        color: var(--c-grey);
    }

    &__list {
        display: flex;
        flex-direction: column;
        margin-block-start: 2rem;

        &--social {
            flex-direction: row;
            padding-block-end: 0;
        }
    }

    &__item {
        @extend %label;

        position: relative;
        padding-block: .75rem;

        &:first-of-type {
            padding-block-start: 0;
        }

        &:last-of-type {
            padding-block-end: 0;
        }
    }

    &__link {
        text-decoration: none;
        color: currentColor;

        &::after {
            @extend %click-extend;

            content: "";
        }
    }

    &__privacy-statement-link {
        @extend %label;

        margin-block-start: .75rem;
        padding-block: .75rem;
        text-decoration: none;

        @include for-size(tablet-landscape-up) {
            margin-block-start: auto;
        }
    }

    &__copyright {
        @extend %label;

        margin-block-start: .75rem;
    }

    &__scroll-to-top {
        margin-block-start: 1.5rem;

        @include for-size(tablet-landscape-up) {
            display: none;
            visibility: hidden;
        }
    }
}
