:root {
    --c-red-rgb:        250, 60, 20;
    --c-red:            rgb(var(--c-red-rgb));
    --c-red-dark-rgb:   186, 32, 0;
    --c-red-dark:       rgb(var(--c-red-dark-rgb));
    --c-pink-rgb:       255, 80, 175;
    --c-pink:           rgb(var(--c-pink-rgb));
    --c-blue-rgb:       150, 150, 255;
    --c-blue:           rgb(var(--c-blue-rgb));
    --c-purple-rgb:     185, 0, 120;
    --c-purple:         rgb(var(--c-purple-rgb));
    --c-yellow-rgb:     255, 240, 50;
    --c-yellow:         rgb(var(--c-yellow-rgb));
    --c-orange-rgb:     255, 160, 0;
    --c-orange:         rgb(var(--c-orange-rgb));
    --c-cyan-rgb:       160, 220, 255;
    --c-cyan:           rgb(var(--c-cyan-rgb));
    --c-sky-rgb:        216, 239, 251;
    --c-sky:            rgb(var(--c-sky-rgb));
    --c-green-rgb:      105, 200, 165;
    --c-green:          rgb(var(--c-green-rgb));

    --c-background-rgb: 220, 242, 255;
    --c-background:     rgb(var(--c-background-rgb));

    --c-white-rgb:      255, 255, 255;
    --c-white:          rgb(var(--c-white-rgb));

    --c-grey-rgb:       128, 128, 128;
    --c-grey:           rgb(var(--c-grey-rgb));

    --c-black-rgb:      61, 73, 109;
    --c-black:          rgb(var(--c-black-rgb));

    --c-primary:        rgb(var(--c-red-rgb));
    --c-primary-dark:   rgb(var(--c-red-dark-rgb));

    --c-positive:       rgb(var(--c-green-rgb));
    --c-negative:       rgb(var(--c-red-rgb));
    --c-warning:        rgb(var(--c-orange-rgb));

    --c-accent:         var(--c-primary);

    --f-primary:        "Gotham", system-ui, sans-serif;
    --f-secondary:      "Appetite", sans-serif;

    --fw-regular:       400;
    --fw-medium:        500;

    --wrapper-width:        72rem;
    --wrapper-padding:      1.25rem;

    --menu-height:          8.75rem;

    --border-radius:        .5rem;
    --border-radius-large:  1.5rem;
    --box-shadow:           0 2px 4px 0 rgba(var(--c-black-rgb), .2);

    --input-color:              var(--c-black);
    --input-border:             1px solid var(--c-white);
    --input-border-radius:      var(--border-radius-large);
    --input-background-color:   rgba(var(--c-white-rgb), .25);
    --input-box-shadow:         inset 0 0 .75rem 0 rgba(var(--c-white-rgb), .5);
    --input-backdrop-filter:    blur(8px);
}

::backdrop {
    --c-full-black-rgb:     0, 0, 0;
    --c-full-black:         rgb(var(--c-full-black-rgb));
}
