@import "src/styles";

.image-slice {
    width: 100%;
    height: 50rem;
    max-height: 90vh;
    position: relative;

    &__image {
        @extend %click-extend;

        object-fit: cover;
    }
}
