@import "src/styles";

.hydration-station-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2.5rem;

    &__text-wrapper {
        order: 1;
        position: relative;
        z-index: 10;
        text-align: start;
        padding-inline: var(--wrapper-padding);

        @include for-size(tablet-landscape-up) {
            padding-inline-start: 3.25rem;
        }

        @include for-size(laptop-up) {
            padding-inline-start: var(--wrapper-padding);
        }

        @media (orientation: landscape) {
            position: absolute;
            top: 50%;
            left: max(var(--wrapper-padding), calc((100vw - var(--wrapper-width)) / 2));
            z-index: 20;
            translate: 0 -50%;
            width: max-content;
        }
    }

    &__switch {
        position: absolute;
        left: 50%;
        bottom: calc(100% + 2.5rem);
        width: 12rem;
        margin-block-end: 1.5rem;
        translate: -50% 0;

        @media (orientation: landscape) {
            position: static;
            translate: unset;
        }
    }

    &__title-button {
        @extend %button--no-background;

        padding: 0;

        & + & {
            margin-block-start: .5rem;

            @include for-size(tablet-portrait-up) {
                margin-block-start: 1.5rem;
            }
        }
    }

    &__title {
        @extend %heading-2;

        color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: var(--c-pink);

        &[data-active="true"] {
            color: var(--c-pink);
        }
    }

    &__description {
        max-width: 80vw;
        margin-block-start: 2rem;
    }

    &__call-to-action {
        max-width: 80vw;
        margin-block-start: 2rem;
        text-align: center;
        line-height: 1.5;
    }

    &__video-wrapper {
        display: flex;
        position: relative;

        &--is-mobile {
            min-height: min(calc((100vw / 3) * 4), 70vh);

            @media (orientation: landscape) {
                min-height: unset;
            }
        }
    }

    &__controls-wrapper {
        position: absolute;
        inset-block-end: 0;
        inset-inline-end: 0;
        z-index: 10;
        padding-block: 2rem;
        padding-inline: var(--wrapper-padding);

        @media (orientation: landscape) {
            padding-block: var(--wrapper-padding);
        }
    }

    &__control-button {
        @include circle(2.5rem);

        padding: 0;
    }

    &__video {
        width: 100vw;
        height: 100%;
        max-height: 70vh;
        object-fit: cover;
        object-position: top right;
        pointer-events: none;

        @media (orientation: landscape) {
            height: 100vh;
            max-height: 100vh;
            object-fit: contain;
        }
    }
}
