.picture {
    display: block;
    width: fit-content;
    max-width: 100%;

    &__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
