@import "src/styles";

.link-icon-button {
    @extend %button;

    display: inline-flex;
    align-items: center;
    gap: .625rem;

    &--align-right {
        flex-direction: row-reverse;
    }

    &--is-disabled {
        pointer-events: none;
        opacity: .5;
    }

    &__icon {
        display: block;
        height: 1em;
    }
}
