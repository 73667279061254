@import "src/styles";

$component: playground-section-desktop-photo-list;

.#{$component} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1.5rem;
    position: relative;
    z-index: 30;
    width: 100%;
    padding-block-end: 7.5%;

    &__button {
        width: 50%;
        max-width: 25rem;
        margin-inline: auto;

        &:nth-child(1) {
            --playground-animation-name: float-up;
            --playground-animation-duration: 8s;

            z-index: 30;
            translate: 0 15%;
            rotate: 5deg;

            @include rtl {
                rotate: -5deg;
            }
        }

        &:nth-child(2) {
            --playground-animation-name: float-down;
            --playground-animation-duration: 9s;

            z-index: 40;
            translate: 0 -6%;
            rotate: -5deg;

            @include for-size(tablet-portrait-up) {
                z-index: 20;
            }

            @include rtl {
                rotate: 5deg;
            }
        }

        &:nth-child(3) {
            --playground-animation-name: float-up;
            --playground-animation-duration: 10s;

            z-index: 10;
            translate: -15% 10%;
            rotate: 5deg;

            @include rtl {
                translate: 15% 10%;
                rotate: -5deg;
            }
        }

        &:hover {
            scale: 1.1;
            rotate: 0deg;

            .#{$component}__picture {
                animation-play-state: paused;
            }
        }
    }

    &__button-wrapper {
        animation: var(--playground-animation-name) var(--playground-animation-duration) ease-in-out infinite;

        @media (prefers-reduced-motion) {
            animation: none;
        }
    }

    &__dialog {
        width: 100%;
        max-width: unset;
        height: 100vh;
        height: 100svh;
        max-height: unset;
        margin: 0;
        border-radius: 0;
        padding: 2rem 0;
        background: none;
    }

    &__dialog-content {
        position: relative;
        max-width: 36rem;
        width: 100%;
        margin-inline: auto;
        border-radius: 1rem;
        padding: 1.25rem 1.25rem 1rem;
        background-color: var(--c-white);
    }

    &__dialog-video {
        width: 100%;
    }

    &__dialog-video-caption {
        margin-block-start: .5rem;
        font-size: 1rem;
        font-weight: var(--fw-regular);
        color: var(--c-black);
        text-align: start;
    }
}