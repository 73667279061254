@import "src/styles";

.navigation-item {

    &__link {
        position: relative;
        display: inline-block;
        padding-block: .625rem;
        padding-inline: 1.25rem;
        font-family: var(--f-secondary);
        font-size: 1.5rem;
        text-transform: lowercase;
        text-decoration: none;
        line-height: 1;
        color: var(--c-white);
        translate: 1.25rem 0;

        @include rtl {
            translate: -1.25rem 0;
        }

        &:hover::after,
        &:focus::after {
            opacity: 1;
        }

        &--is-active {
            color: var(--c-yellow);
        }

        &::after {
            @extend %click-extend;

            content: "";
            z-index: 10;
            border-radius: var(--border-radius-large);
            background-color: rgba(var(--c-black-rgb), .1);
            transition: opacity .1s ease;
            opacity: 0;
        }
    }

    &__label {
        position: relative;
        z-index: 20;
    }
}
