@import "src/styles";

.gym-locator-page {
    @extend %storepoint-embed;

    padding-block-start: var(--menu-height);
    background-color: var(--c-background);

    &__title {
        @extend %heading-2;

        color: var(--c-purple);
    }

    &__locator-wrapper {
        padding-block-end: 5rem;
    }

    &__locator {
        position: relative;
        margin-block-start: 2rem;
        z-index: 1;
    }

    &__partner-grid {
        margin-block-start: 3rem;
    }
}
