.region-selector {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: var(--c-white);

    &__label {
        flex: 1;
        display: block;
        padding: 0;
        font-size: .875rem;
        text-align: end;
        color: currentColor;
    }

    &__input-wrapper {
        display: flex;
        align-items: center;
    }

    &__input {
        appearance: none;
        width: 100%;
        margin-inline-start: auto;
        padding: .5rem;
        padding-inline-end: 1.5rem;
        font: inherit;
        font-family: var(--f-secondary);
        font-size: 1.5rem;
        font-weight: var(--fw-medium);
        text-transform: lowercase;
        text-align-last: end;
        line-height: 1;
        color: currentColor;
        cursor: pointer;
        border: none;
        background-color: transparent;
    }

    &__option {
        color: var(--c-black);
    }

    &__icon {
        position: absolute;
        inset-inline-end: .25rem;
        width: .625rem;
        pointer-events: none;
    }
}
