.article-overview-background {

    &__gradient-start {
        stop-color: rgba(var(--c-cyan-rgb), 1);
    }

    &__gradient-end {
        stop-color: rgba(var(--c-cyan-rgb), .25);
    }

    &__svg-wave {
        stroke: var(--c-cyan);
        stroke-width: 1px;
    }
}
