@import "src/styles";

.prismic-slice-title {
    @extend %heading;

    &--size-small { @extend %heading-3; }
    &--size-big { @extend %heading-2; }

    &--colour-black { color: var(--c-black); }
    &--colour-red { color: var(--c-red); }
    &--colour-pink { color: var(--c-pink); }
    &--colour-blue { color: var(--c-blue); }
    &--colour-purple { color: var(--c-purple); }
    &--colour-yellow { color: var(--c-yellow); }
    &--colour-orange { color: var(--c-orange); }
    &--colour-cyan { color: var(--c-cyan); }
    &--colour-green { color: var(--c-green); }
}
