@import "src/styles";

.vacancy-expectations-slice {
    display: flex;
    flex-direction: column;
    gap: 4rem;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        gap: 7.5rem;
    }

    &__company-wrapper,
    &__applicant-wrapper {
        width: 100%;
    }

    &__title {
        @extend %heading-4;

        color: var(--c-blue);
    }

    &__description {
        @extend %html-format;

        margin-block-start: 2rem;
    }
}
