@import "src/styles";

.wrapper {
    width: 100%;
    max-width: var(--wrapper-width);
    margin-inline: auto;
    padding-inline: var(--wrapper-padding);

    @include for-size(tablet-landscape-up) {
        padding-inline-start: 3.25rem;
    }

    @include for-size(laptop-up) {
        padding-inline-start: var(--wrapper-padding);
    }
}
