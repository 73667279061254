@import "src/styles";

.toast {
    --c-toast-state: var(--c-blue);

    display: flex;
    position: fixed;
    width: calc(100% - (var(--wrapper-padding) * 2));
    min-width: 20rem;
    max-width: 36rem;
    padding: .625rem;
    line-height: 1.5;
    color: var(--c-black);
    border-radius: var(--border-radius);
    background-color: var(--c-white);
    backdrop-filter: var(--input-backdrop-filter);
    box-shadow: var(--box-shadow);
    transition: translate var(--toast-transition-duration) ease, opacity var(--toast-transition-duration) ease;
    opacity: 0;

    &--inset-block-start {
        inset-block-start: var(--wrapper-padding);
    }

    &--inset-block-end {
        inset-block-end: var(--wrapper-padding);
    }

    &--inset-inline-start {
        inset-inline-start: var(--wrapper-padding);
        translate: negative(calc(100% + var(--wrapper-padding))) 0;

        @include rtl {
            translate: calc(100% + var(--wrapper-padding)) 0;
        }
    }

    &--inset-inline-end {
        inset-inline-end: var(--wrapper-padding);
        translate: calc(100% + var(--wrapper-padding)) 0;

        @include rtl {
            translate: negative(calc(100% + var(--wrapper-padding))) 0;
        }
    }

    &--positive {
        --c-toast-state: var(--c-positive);
    }

    &--warning {
        --c-toast-state: var(--c-warning);
    }

    &--negative {
        --c-toast-state: var(--c-negative);
    }

    &--is-revealed {
        translate: 0 0;
        opacity: 1;

        // Required to match specificity
        @include rtl {
            translate: 0 0;
        }
    }

    &__message-wrapper {
        width: 100%;
        padding: 1rem;
        padding-inline-end: 3rem;
        border-radius: var(--border-radius);
        border: 1px solid var(--c-toast-state);
    }

    &__title {
        font-family: var(--f-secondary);
        font-size: 1.5rem;
        font-weight: var(--fw-medium);
        line-height: 1.5;
    }

    &__description {
        margin-block-start: 1rem;
    }

    &__close-button {
        @extend %button--no-background;

        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        padding: 1rem;
        padding-block-start: 1.625rem;
        padding-inline-end: 1.625rem;
        color: inherit;
    }

    &__close-icon {
        height: 1rem;
    }
}
