@import "src/styles";

.contact-section {
    --c-accent: var(--c-primary);

    position: relative;
    padding-block: var(--menu-height) 5rem;
    overflow: hidden;

    @include for-size(tablet-portrait-up) {
        padding-block: 10rem;
    }

    @include for-size(laptop-up) {
        padding-block-start: 12.5rem;
    }

    &__description-and-contact-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 20;
        gap: 4rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            gap: 2rem;
        }
    }

    &__description-wrapper {
        width: 100%;
        max-width: 40rem;
    }

    &__title {
        @extend %heading-2;

        color: var(--c-orange);
    }

    &__description {
        max-width: 28rem;
        margin-block-start: 3rem;
    }

    &__form {

        @include for-size(tablet-landscape-up) {
            min-width: 28rem;
        }
    }

    &__background-wrapper {
        @extend %click-extend;
        @extend %flex-align-center;

        z-index: 10;
        inset-block-start: -5%;
        inset-inline-start: -20%;
        width: 100%;
        height: 100%;

        @include for-size(phone-only) {
            inset-block-start: -15%;
            height: 112.5%;
        }

        @include for-size(tablet-landscape-up) {
            inset-block-start: 0;
            inset-inline-start: -25%;
            width: 150%;
            height: 100%;
        }

        @include rtl {
            scale: -1 1;
        }
    }

    &__background {
        min-width: 130rem;
        height: 100%;

        @include for-size(phone-only) {
            min-width: 100rem;
        }

        @include for-size(tablet-portrait-up) {
            width: 100%;
            max-width: 130rem;
        }
    }
}
