@import "src/styles";

.vacancy-header {
    width: 100%;
    overflow-x: clip;

    &__back-button {
        @extend %button--call-to-action;

        &::after {
            order: -1;
            rotate: .5turn;
        }
    }

    &__content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        margin-block-start: 2rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            gap: 3rem;
            margin-block-start: 4rem;
        }
    }

    &__text-wrapper {
        width: 100%;
        max-width: 48rem;
    }

    &__title {
        @extend %heading-1;

        font-size: 3.5rem;
        color: var(--c-blue);

        @include for-size(tablet-landscape-up) {
            font-size: 4.25rem;
        }

        @include for-size(desktop-up) {
            font-size: 5.125rem;
        }
    }

    &__feature-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-block-start: 2rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            gap: 2.5rem;
        }
    }

    &__feature-item {
        display: flex;
        align-items: center;
        gap: 1rem;
        color: var(--c-blue);
    }

    &__feature-icon {
        height: 2.5rem;
    }

    &__feature-label {
        font-family: var(--f-secondary);
        font-size: 1.5rem;
        text-transform: lowercase;
    }

    &__date-wrapper {
        display: flex;
        flex-direction: column;
        margin-block-start: 2rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__date-label {
        width: 100%;
        font-size: .875rem;
    }

    &__apply-button {
        margin-block-start: 2rem;

        @include for-size(tablet-landscape-up) {
            margin-block-start: 4rem;
        }
    }

    &__image-wrapper {
        width: 100%;
    }

    &__image {
        width: 100%;
        border-radius: var(--border-radius);
        border: .5rem solid var(--c-white);
        box-shadow: var(--box-shadow);
        rotate: 5deg;

        @include for-size(tablet-portrait-up) {
            rotate: 10deg;
        }
    }
}
