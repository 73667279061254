@import "src/styles";

.article-content {
    position: relative;

    &__back-button {
        @extend %button--no-background;

        align-items: baseline;
        padding: 0;
        font-size: .875rem;
        font-weight: var(--fw-medium);
        color: var(--c-primary);
    }

    &__back-button-icon {
        height: .75em;
    }

    &__header {
        margin-block-start: 2rem;
    }

    &__body {
        margin-block-start: 5rem;
    }

    &__intro {
        margin-block-start: 2.5rem;
        white-space: pre-line;
    }

    &__renderer {
        width: 100%;
        margin-block-start: 1.5rem;
    }
}
