@import "src/styles";

$component: "next-flavour";

.#{$component} {
    display: flex;
    justify-content: center;
    overflow-x: clip;

    &__wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: center;
            width: fit-content;
        }

        &:hover,
        &:focus-within {

            .#{$component}__icon-wrapper {
                color: var(--c-white);
                background-color: var(--c-primary);
            }
        }
    }

    &__picture {
        width: 10rem;
        height: 10rem;

        @include rtl {
            scale: -1 1;
        }
    }

    &__text-wrapper {
        display: flex;
        align-items: center;
        gap: 2rem;
    }

    &__label-wrapper {
        width: min-content;
    }

    &__label {
        @extend %heading-3;

        word-break: normal;
        color: var(--c-pink);
    }

    &__link-button {
        @extend %button--call-to-action;

        margin-block-start: 1rem;

        &::before {
            @extend %click-extend;

            content: "";
        }
    }

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(4rem);

        color: var(--c-primary);
        background-color: var(--c-white);
        box-shadow: var(--box-shadow);
    }

    &__icon {
        pointer-events: none;

        @include rtl {
            scale: -1 1;
        }
    }

    &__blob-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -10;
        translate: -2rem 25%;

        @include rtl {
            translate: 2rem 25%;
        }

        @include for-size(tablet-portrait-up) {
            translate: -7.5% -22.5%;

            @include rtl {
                translate: -7.5% -22.5%;
            }
        }
    }

    &__blob {
        display: block;
        position: relative;
        width: 28rem;
        height: 14rem;

        @include for-size(tablet-portrait-up) {
            width: 35rem;
            height: 18.75rem;
        }
    }
}
