@import "src/styles";

$component: "fixed-navigation-item";

.#{$component} {
    position: relative;
    display: flex;
    align-items: center;

    &--blob-is-revealed {

        .#{$component}__blob-wrapper {
            transition: opacity .075s ease;
        }
    }

    &--is-active &__indicator {
        color: var(--c-primary);
        border: 1px solid currentColor;
    }

    &:hover &__indicator {
        color: var(--c-primary);
    }

    &:hover &__label {
        opacity: 1;
    }

    &__label-wrapper {
        display: inline-flex;
        align-items: center;
    }

    &__link {
        @extend %button--no-background;

        position: relative;
        padding: 1rem;
        text-decoration: none;

        &:hover {

            & ~ .#{$component}__blob-wrapper {
                opacity: 1;
            }
        }

        &:focus-visible {
            outline: none;

            .#{$component}__indicator {
                color: var(--c-primary);
                border: 1px solid currentColor;
            }

            & ~ .#{$component}__label {
                opacity: 1;
            }
        }

        &:focus ~ .#{$component}__blob-wrapper {
            transition: translate .3s ease;
        }
    }

    &__indicator {
        @extend %flex-align-center;
        @include circle(1rem);

        color: var(--c-orange);
        border-color: transparent;
        transition: color .3s ease, border-color .3s ease;

        &::before {
            @include circle(.5rem);

            content: "";
            background-color: currentColor;
        }
    }

    &__label {
        @extend %label;

        pointer-events: none;
        transition: opacity .3s ease;
        opacity: 0;
    }

    &__blob-wrapper {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        opacity: 0;
        pointer-events: none;
        translate: calc(var(--fixed-navigation-blob-x) + 1.5rem) calc(var(--fixed-navigation-blob-y) + 1.5rem);
        transition: opacity .3s ease;

        &:focus-within {
            transition: translate .3s ease;
        }

        @include rtl {
            translate: calc(var(--fixed-navigation-blob-x) - 1.5rem) calc(var(--fixed-navigation-blob-y) + 1.5rem);
        }
    }

    &__blob {
        position: absolute;
        top: 0; // Property should be non-logical for positioning to work
        left: 0; // Property should be non-logical for positioning to work
        width: 5rem;
        stroke-width: 2;
        pointer-events: none;
        translate: -50% -50%;
        animation: spin 5s linear infinite;

        @include rtl {
            animation-direction: reverse;
        }
    }

    &__blob-path {
        fill: none;
        stroke: var(--c-primary);
    }
}
