@import "src/styles";

.personality-test-result {
    position: relative;
    overflow-x: clip;

    @include for-size(tablet-landscape-up) {
        position: static;
    }

    &--is-loaded &__wrapper {
        opacity: 1;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        position: relative;
        z-index: 10;
        transition: opacity .3s ease;
        opacity: 0;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            align-items: flex-start;
            gap: 8rem;
        }
    }

    &__card {
        position: relative;
        z-index: 20;
        max-width: 30rem;
        rotate: -2deg;

        @include rtl {
            rotate: 2deg;
        }

        @include for-size(tablet-landscape-up) {
            padding: 2rem;
        }
    }

    &__flavour-wrapper {
        flex: 1;
        position: relative;
        z-index: 10;
        padding-block: 2.5rem;

        @include for-size(tablet-landscape-up) {
            padding-block: 7.5rem;
        }
    }

    &__description-wrapper {
        position: relative;
        z-index: 10;
    }

    &__title {
        @extend %heading;

        max-width: 18rem;
        font-size: 2.25rem;
        color: var(--c-green);

        @include for-size(tablet-landscape-up) {
            max-width: unset;
            font-size: 5rem;
        }
    }

    &__description {
        margin-block-start: 2rem;

        @include for-size(tablet-landscape-up) {
            margin-block-start: 2.5rem;
        }
    }

    &__read-more-link {
        @extend %button--call-to-action;

        margin-block-start: 2rem;

        @include for-size(tablet-landscape-up) {
            margin-block-start: 3.25rem;
        }
    }

    &__picture {
        position: absolute;
        z-index: 0;

        &--primary {
            inset-block-start: 0;
            inset-inline-end: 0;
            width: 18rem;
            translate: 50% -50%;

            @include rtl {
                translate: -50% -50%;
            }

            @include for-size(tablet-landscape-up) {
                translate: 100% 0;
            }
        }

        &--secondary {
            display: none;
            inset-block-end: 0;
            inset-inline-start: 0;
            height: 24rem;
            translate: -85% 75%;

            @include rtl {
                translate: 85% 75%;
            }

            @include for-size(tablet-landscape-up) {
                display: block;
            }
        }
    }

    &__wave-background {
        position: absolute;
        inset-block-end: 30%;
        inset-inline-start: -10%;
        z-index: 0;
        width: 140%;
        height: 50%;
        pointer-events: none;
        scale: -1 1;

        @include for-size(tablet-portrait-up) {
            width: 120%;
        }

        @include for-size(tablet-landscape-up) {
            inset-inline-start: 0;
            width: 100%;
        }

        @include rtl {
            scale: 1 1;
        }
    }
}
