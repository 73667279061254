%html-format {
    line-height: 2;

    & > * + * {
        margin-block-start: 1rem;
    }

    h2 {
        @extend %heading-2;
    }

    h3 {
        @extend %heading-3;
    }

    h4 {
        @extend %heading-4;
    }

    ul {
        padding-inline-start: 0;
    }

    ol {
        padding-inline-start: .5rem;
    }

    ul li {
        position: relative;
        padding-inline-start: 2rem;

        &::before {
            content: "";
            display: block;
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            min-width: .5rem;
            max-width: .5rem;
            min-height: .5rem;
            max-height: .5rem;
            border-radius: .5rem;
            background-color: var(--c-orange);
            outline: 1px solid var(--c-orange);
            outline-offset: .25rem;
            translate: .25rem .75rem;
        }

        & + li {
            margin-block-start: .5rem;
        }
    }

    a {
        font-weight: var(--fw-medium);
        color: var(--c-red);
        text-decoration: none;

        &:hover {
            color: var(--c-orange);
        }

        &:visited {
            color: var(--c-purple);
        }
    }
}
