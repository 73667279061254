@import "src/styles";

.input-label {
    @extend %label;

    display: inline-flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    padding-inline: 1.25rem;
    color: var(--c-black);

    &__additional-label {
        color: rgba(var(--c-grey-rgb), .7);
    }
}
