@import "src/styles";

.vacancy-call-to-action-slice {

    &__inner-wrapper {
        max-width: 44rem;
    }

    &__title {
        @extend %heading-1;

        font-size: 3.5rem;
        color: var(--c-orange);

        @include for-size(tablet-landscape-up) {
            font-size: 4.75rem;
        }

        @include for-size(tablet-landscape-up) {
            font-size: 6.75rem;
        }
    }

    &__description {
        margin-block-start: 2rem;
    }

    &__contact-wrapper {
        margin-block-start: 2rem;
    }

    &__contact-name {
        @extend %heading-4;

        color: var(--c-blue);
    }

    &__contact-phone {
        display: inline-block;
        margin-block-start: 1rem;
        color: currentColor;
        text-decoration: none;
    }
}
