@import "src/styles";

.i18n-settings {
    --i18n-settings-item-translation: 0 -.5rem;
    --i18n-settings-item-opacity: 0;

    &--is-ready {
        --i18n-settings-item-translation: 0 0;
        --i18n-settings-item-opacity: 1;
    }

    &__cookie-notice-wrapper,
    &__region-selector-wrapper,
    &__language-item {
        transition: translate .1s ease, opacity .1s ease;
        translate: var(--i18n-settings-item-translation);
        opacity: var(--i18n-settings-item-opacity);
    }

    &__cookie-notice-wrapper {
        font-style: italic;
        text-align: end;
        margin-block-end: 1.5rem;
        color: var(--c-white);
    }

    &__cookie-notice {
        line-height: 1.125;
        display: inline;
    }

    &__cookie-button {
        @extend %button--no-background;

        display: inline;
        margin-inline-start: .5rem;
        padding: 0;
        font-weight: inherit;
        text-decoration: underline;
    }

    &__language-list {
        margin-block-start: 3rem;
        padding: 0;
        text-align: end;
    }

    &__language-item {
        list-style-type: none;
    }

    &__language-button {
        @extend %button--no-background;

        position: relative;
        display: inline-block;
        padding-block: .625rem;
        padding-inline: 1.25rem;
        font-family: var(--f-secondary);
        font-size: 1.5rem;
        text-transform: lowercase;
        letter-spacing: unset;
        translate: 1.25rem 0;

        @include rtl {
            translate: -1.25rem 0;
        }

        &:hover::after,
        &:focus::after {
            opacity: 1;
        }

        &--is-active {
            color: var(--c-yellow);
        }

        &::after {
            @extend %click-extend;

            content: "";
            z-index: 10;
            border-radius: var(--border-radius-large);
            background-color: rgba(var(--c-black-rgb), .1);
            transition: opacity .1s ease;
            opacity: 0;
        }
    }

    &__language-label {
        position: relative;
        z-index: 20;
    }
}
