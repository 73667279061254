%input {
    width: 100%;
    margin: 0;
    padding-block: 1rem;
    padding-inline: 2rem;
    font: inherit;
    color: var(--input-color);
    border: var(--input-border);
    border-radius: var(--input-border-radius);
    background-color: var(--input-background-color);
    box-shadow: var(--input-box-shadow);
    backdrop-filter: var(--input-backdrop-filter);

    &:disabled {
        cursor: not-allowed;
        opacity: .5;
    }

    &:focus {
        outline: none;
        border-color: var(--c-accent);
    }
}
