@import "src/styles";

.fluid-title {
    position: relative;

    &__html-title {
        position: absolute;
        opacity: 0;
    }

    &__shadow,
    &__svg {
        @extend %click-extend;
    }

    &__svg {
        fill: var(--c-white);
    }

    &__shadow {
        padding-block-start: 14%;
    }

    &__shadow-line {
        margin-inline-start: 100vw;
        width: 0;
        height: .25rem;
        background: rgba(var(--c-blue-rgb),0.2);
        box-shadow: -100vw 0 60px 20px rgba(var(--c-blue-rgb), .3);

        & + & {
            margin-block-start: 32%;
        }

        @include for-size(tablet-portrait-up) {
            box-shadow: -100vw 0 120px 40px rgba(var(--c-blue-rgb), .3);
        }

        @include rtl {
            box-shadow: 100vw 0 60px 20px rgba(var(--c-blue-rgb), .3);

            @include for-size(tablet-portrait-up) {
                box-shadow: 100vw 0 120px 40px rgba(var(--c-blue-rgb), .3);
            }
        }
    }

    &__svg-bounding-box {
        fill: transparent;
    }

    &__svg-title {
        font-family: var(--f-secondary);
        font-size: 9rem;
        font-weight: var(--fw-regular);
        text-transform: lowercase;
        letter-spacing: 1px;
        color: var(--c-black);

        @include rtl {
            letter-spacing: 0;
        }
    }

    &__svg-masked-text {
        fill: var(--c-orange);
    }
}
