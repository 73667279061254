@import "src/styles";

.personality-test-intro {

    &__title {
        @extend %heading;

        font-size: 2.25rem;
        color: var(--c-green);

        @include for-size(tablet-portrait-up) {
            font-size: 5rem;
        }
    }

    &__description {
        margin-block-start: 4rem;
    }

    &__button-wrapper {
        display: flex;
        flex-direction: column-reverse;
        gap: 4rem;
        margin-block-start: 4rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__button {
        @extend %button--call-to-action;
    }
}
