@import "button";
@import "html";
@import "input";
@import "print";
@import "storepoint";
@import "typography";

%flex-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

%click-extend {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

%draggable {
    background-color: var(--c-white);
    box-shadow: var(--box-shadow);
}

%skeleton-fade {
    position: relative;
    overflow: hidden;

    &::after {
        @extend %click-extend;

        content: "";
        background: linear-gradient(
            90deg,
            transparent 0%,
            rgba(var(--c-white-rgb), .25) 50%,
            transparent 100%
        );
        animation: skeleton-load 2s ease infinite;

        @include rtl {
            scale: -1 1;
            animation-direction: reverse;
        }
    }
}
