@import "src/styles";

.article-overview-skeletons {

    &__title-wrapper {
        max-width: 46rem;
    }

    &__title {
        font-size: 1.75rem;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        margin-block-start: 2rem;

        @include for-size(tablet-portrait-up) {
            gap: 5rem;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin-block-start: 2rem;

        @include for-size(tablet-portrait-up) {
            gap: 2rem;
            flex-direction: row;
        }

        &:nth-of-type(even) {

            @include for-size(tablet-portrait-up) {
                flex-direction: row-reverse;
            }
        }
    }

    &__thumbnail {
        @extend %skeleton-fade;

        width: 100%;
        height: 20rem;
        border-radius: var(--border-radius);
        background-color: var(--c-cyan);
    }

    &__text-wrapper {
        width: 100%;
        padding: 1.25rem;
    }

    &__item-title {
        font-size: 1.5rem;
    }

    &__info {
        margin-block-start: 1rem;
    }

    &__intro-wrapper {
        margin-block-start: 1.5rem;
    }

    &__intro-line {

        & + & {
            margin-block-start: .5rem;
        }
    }

    &__button {
        @extend %skeleton-fade;

        width: 8rem;
        height: 3rem;
        margin-block-start: 2rem;
        border-radius: var(--border-radius-large);
        background-color: var(--c-cyan);
    }
}
