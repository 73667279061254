@import "src/styles";

$component: "usp-card";

.#{$component} {
    --c-accent: var(--c-blue);
    --usp-card-rotation: 0deg;
    --usp-card-animation-duration: 5s;
    --usp-card-transform-origin-x: 30%;

    position: relative;
    z-index: 10;
    width: 100%;
    aspect-ratio: 1;
    padding: 0;
    background-color: transparent;
    perspective: 60rem;
    rotate: var(--usp-card-rotation);
    transition: filter .3s ease;
    animation: float-up var(--usp-card-animation-duration) ease-in-out infinite;

    @include rtl {
        --usp-card-transform-origin-x: 70%;
    }

    &:nth-child(odd) {
        --usp-card-transform-origin-x: 70%;

        @include rtl {
            --usp-card-transform-origin-x: 30%;
        }
    }

    &--prefers-reduced-motion {
        animation: none;

        .#{$component}__flip-wrapper {
            transition-duration: 1ms;
        }
    }

    &--is-deselecting {
        z-index: 20;
    }

    &--is-selected {
        --c-accent: var(--c-orange);

        z-index: 30;

        .#{$component}__flip-wrapper {
            transform: rotateY(180deg) scale(1.5);
        }

        .#{$component}__front-wrapper,
        .#{$component}__back-wrapper {
            pointer-events: none;
        }
    }

    &--is-not-selected {
        filter: blur(2px);
    }

    &__flip-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform .5s ease;
        transform-style: preserve-3d;
        transform-origin: var(--usp-card-transform-origin-x) 50%;
        will-change: transform;

        @include for-size(tablet-portrait-up) {
            transform-origin: center;
        }
    }

    &__front-wrapper,
    &__back-wrapper {
        @extend %click-extend;

        padding: .625rem;
        border-radius: var(--border-radius);
        background-color: var(--c-white);
        backface-visibility: hidden;
        box-shadow: var(--box-shadow);
    }

    &__back-wrapper {
        transform: rotateY(180deg);

        .#{$component}__inner-wrapper {
            gap: .5rem;
        }
    }

    &__inner-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        width: 100%;
        height: 100%;
        padding: .75rem;
        border-radius: var(--border-radius);
        border: 1px solid var(--c-accent);
        pointer-events: none;

        @include for-size(tablet-portrait-up) {
            padding: 1rem;
        }
    }

    &__icon {
        width: 37.5%;
        height: unset;
        color: var(--c-accent);

        @include for-size(tablet-portrait-up) {
            width: 50%;
        }
    }

    &__title-button {
        @extend %button--no-background;

        padding: 0;
        letter-spacing: 0;
        border-radius: 0;

        &:hover,
        &:focus {
            outline: none;
        }
    }

    &__title {
        @extend %heading-4;

        font-size: .875rem;

        @include for-size(tablet-portrait-up) {
            font-size: 1.5rem;
        }

        &--is-small {
            font-size: .75rem;

            @include for-size(tablet-portrait-up) {
                font-size: 1rem;
            }
        }
    }

    &__description {
        font-size: .5rem;

        @include for-size(tablet-portrait-up) {
            font-size: .625rem;
        }
    }

    &__flip-button {
        @extend %button--no-background;

        display: none;
        align-self: flex-end;
        padding: 0;
        font-size: .75rem;
        color: var(--c-primary);
        pointer-events: all;

        @include touch-screen {
            display: flex;
        }

        &::after {
            @extend %click-extend;

            content: "";
        }
    }

    &__close-button {
        @extend %click-extend;
        @extend %button--no-background;

        display: none;
        padding: .75rem;
        pointer-events: all;
        color: var(--c-accent);
        border-radius: 0;

        @include touch-screen {
            display: block;
        }
    }

    &__close-icon {
        position: absolute;
        inset-block-start: 1.25rem;
        inset-inline-end: 1.25rem;
        height: .625rem;
    }
}
