@import "src/styles";

.personality-test-background {
    width: 100%;
    height: 100%;
    pointer-events: none;

    &--is-ending &__fruit-wrapper {
        opacity: 0;
    }

    &__fruit-wrapper {
        position: absolute;
        inset-block-end: -5%;
        inset-inline-end: 0;
        max-width: 100%;
        z-index: 10;
        transition: opacity .3s ease;
        opacity: 1;

        @include rtl {
            scale: -1 1;
        }

        @include for-size(tablet-portrait-up) {
            max-width: 85%;
            inset-block-start: 25%;
            inset-inline-end: -5%;
        }

        @include for-size(tablet-landscape-up) {
            inset-block-start: 10%;
        }

        @include for-size(laptop-up) {
            max-width: 100%;
            inset-block-start: 0;
        }
    }

    &__picture {
        width: 100%;
        scale: 1.5;
        translate: 0 4rem;
    }

    &__video {
        width: 100%;
    }

    &__svg {
        position: absolute;
        inset-block-end: 0;
        inset-inline-start: -10%;
        z-index: 0;
        width: 140%;
        height: 100%;
        pointer-events: none;
        scale: -1 1;

        @include for-size(tablet-portrait-up) {
            width: 120%;
        }

        @include for-size(tablet-landscape-up) {
            inset-inline-start: 0;
            width: 100%;
        }

        @include rtl {
            scale: 1 1;
        }
    }

    &__gradient-start {
        stop-color: var(--c-cyan);
    }

    &__gradient-end {
        stop-color: var(--c-background);
    }

    &__svg-wave {
        stroke: var(--c-cyan);
        stroke-width: 1px;
    }
}
