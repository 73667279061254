$component: playground-section-photo-list;

.playground-section-photo-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    position: relative;
    z-index: 30;
    width: 100%;
    padding-block-end: 15%;

    &__button {
        width: 100%;
        max-width: 25rem;
        margin-inline: auto;
    }

    .pin-spacer:nth-child(1) .#{$component}__button-wrapper {
        translate: 0 -.125rem;
        rotate: 1.25deg;
    }

    .pin-spacer:nth-child(2) .#{$component}__button-wrapper {
        rotate: -1.25deg;
    }

    .pin-spacer:nth-child(3) .#{$component}__button-wrapper {
        translate: 0 .5rem;
        rotate: 1.25deg;
    }
}
