@import "src/styles";

.non-sort-table {

    &__header-row {
        border-top-color: transparent;
    }

    &__hidden-cell {
        @include column-width(0);
    }
}
