@import "src/styles";

.article-preview {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    width: 100%;
    text-decoration: none;

    @include for-size(tablet-portrait-up) {
        flex-direction: inherit;
        justify-content: space-between;
        align-items: center;
        gap: 5rem;
    }

    &__image-wrapper {
        position: relative;
        min-height: 20rem;
        min-width: 20rem;
        overflow: clip;
        border-radius: var(--border-radius);
        background-color: var(--c-cyan);

        @include for-size(tablet-portrait-up) {
            width: 50%;
        }
    }

    &__image {
        @extend %click-extend;

        object-fit: cover;
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        padding-block: 1.25rem;

        @include for-size(tablet-portrait-up) {
            width: 50%;
            padding: 1.25rem;
        }
    }

    &__title {
        @extend %heading-2;

        font-size: 3rem;
        color: var(--c-purple);
    }

    &__article-info-wrapper {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-block-start: 1rem;
        line-height: 2;
        color: var(--c-black);
    }

    &__intro {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-block-start: 1.5rem;
        overflow: hidden;
    }

    &__button {
        @extend %button--call-to-action;

        width: fit-content;
        margin-block-start: 2rem;

        &::before {
            @extend %click-extend;

            content: "";
        }

        @include for-size(tablet-portrait-up) {
            width: fit-content;
        }
    }
}
