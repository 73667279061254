@import "src/styles";

.usp-section {
    position: relative;
    padding-block: 10rem;

    @include for-size(tablet-portrait-up) {
        padding-block: 20rem;
    }

    &__title {
        @extend %heading-2;

        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 30;
        margin: 0 auto;
        width: fit-content;
        color: var(--c-pink);
    }

    &__title-part {
        text-align: center;

        &:first-of-type {
            align-self: flex-start;
            margin-block-start: auto;
        }

        &:last-of-type {
            align-self: flex-end;
            margin-block-end: auto;
        }
    }

    &__carousel {
        position: relative;
        z-index: 20;
        margin-block-start: 3rem;
    }

    &__svg-wrapper {
        @extend %click-extend;
        @extend %flex-align-center;

        z-index: 10;
        pointer-events: none;

        @include rtl {
            transform-origin: center;
            scale: -1 1;
        }
    }

    &__svg {
        width: 100%;
        height: 100%;
        min-width: 70rem;
        transform-origin: 0 0;
        scale: 1.35 1;
    }
}
