@import "src/styles";

.usp-page {
    padding-block-start: var(--menu-height);

    &__title {
        @extend %heading-2;

        position: relative;
        z-index: 20;
        max-width: 6.5em;
        margin-block-start: 2.5rem;
        color: var(--c-orange);
    }

    &__grid {
        z-index: 10;
        margin-block-start: 4rem;
    }
}
