@import "src/styles";

.vacancy-overview {

    &__no-results-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        margin-inline: auto;
        color: var(--c-blue);
    }

    &__no-results-icon {
        height: 3rem;
    }

    &__no-results-text {
        @extend %heading-4;

        color: currentColor;
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2.5rem;

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(2, 1fr);
            gap: 3rem;
        }
    }

    &__item {
        list-style-type: none;
    }
}
