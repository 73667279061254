@import "src/styles";

$component: playground-section;

.#{$component} {
    padding-block: 6rem 0;

    &__photos-and-description {
        position: relative;
        margin-block-start: 1rem;
        padding-block-end: 6rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 0;
            padding-block-end: 12rem;
        }
    }

    &__title {
        @extend %heading-2;

        position: relative;
        z-index: 40;
        min-height: 6rem;
        text-align: center;
        color: var(--c-green);

        @include for-size(tablet-portrait-up) {
            min-height: 10.5rem;
        }

        @include for-size(tablet-landscape-up) {
            min-height: 12rem;
        }
    }

    &__photo-list {
        padding-block-end: 15%;

        @include for-size(tablet-portrait-up) {
            padding-block-end: 7.5%;
        }
    }

    &__description-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 20;
        margin-block-start: 6rem;
        text-align: center;

        @include for-size(phone-only) {
            margin-block-start: 3rem;
        }

        @include for-size(tablet-portrait-up) {
            margin-block-start: 7rem;
        }
    }

    &__description {
        max-width: 28rem;
    }

    &__hashtag {
        @extend %button--call-to-action;

        display: inline;
        width: fit-content;
        margin-block-start: 1rem;
        font-size: 2.5rem;
        line-height: 1;
        word-break: break-all;
        text-decoration: none;
        color: var(--c-pink);

        &::after {
            content: unset;
        }

        @include for-size(phone-only) {
            font-size: 2rem;
        }

        @include for-size(tablet-portrait-up) {
            font-size: 3rem;
        }
    }

    &__social-navigation {
        margin-block-start: 2.5rem
    }

    &__svg-wrapper {
        @extend %click-extend;
        @extend %flex-align-center;

        inset-block-end: 0;
        inset-inline-start: -35%;
        z-index: 10;
        width: 170%;
        height: 150%;

        @include for-size(tablet-portrait-up) {
            inset-block-start: -10%;
            inset-inline-start: -30%;
            width: 150%;
            height: 125%;
        }

        @include rtl {
            transform-origin: center;
            scale: -1 1;
        }
    }

    &__svg {
        width: 100%;
        height: auto;
        aspect-ratio: 1.45;

        @include for-size(phone-only) {
            min-width: 58rem;
        }
    }
}
