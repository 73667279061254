@import "src/styles";

.personality-test-result-card {
    width: 100%;
    padding: 1.5rem;
    border-radius: var(--border-radius);
    background-color: var(--c-white);
    box-shadow: var(--box-shadow);

    @include for-size(tablet-portrait-up) {
        padding: 2rem;
    }

    &__title {
        @extend %heading;

        font-size: 1.25rem;
        line-height: 1.5;
        hyphens: auto;
        color: var(--c-primary);
    }

    &__title-flavour {
        display: block;
        margin-block-start: .25rem;
        font-size: 3rem;
        line-height: 1;
    }

    &__description {
        margin-block-start: 1rem;
    }

    &__picture {
        width: 100%;
        margin-block-start: 1rem;
    }

    &__call-to-action {
        margin-block-start: 1rem;
    }

    &__call-to-action-link {
        color: var(--c-blue);
    }

    &__share {
        margin-block-start: 1rem;
    }
}
