@import "typography";
@import "animations";
@import "variables";

* {
    box-sizing: border-box;
}

:focus:not(:focus-visible) {
    outline: none;
}

html {
    font-size: 100%;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--f-primary);
    font-weight: var(--fw-regular);
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--c-black);
    background-color: var(--c-background);
    background: radial-gradient(circle at 22% 5%, var(--c-white), var(--c-background) 47%);

    &.has-active-modal {
        overflow: hidden;
    }
}

h1, h2, h3 {
    margin: 0;
    font-weight: var(--fw-regular);
}

p {
    margin: 0;
    line-height: 2.25;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

#app-root {
    position: relative;
    z-index: 10;
}

#portal-root {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
}

.grecaptcha-badge {
    display: none !important;
    visibility: hidden;
}

.cleanhub-widget-wrapper {
    position: absolute;
    z-index: 15;
}
