@import "src/styles";

.menu-background {
    --c-menu-background-color: rgba(var(--c-background-rgb), .92);

    position: relative;
    filter: contrast(.95) brightness(1.02);

    &::before {
        @extend %click-extend;

        content: "";
        display: block;
        inset-block-start: -2rem;
        height: 2rem;
        background-color: var(--c-menu-background-color);
    }

    &__svg {
        @extend %click-extend;
    }

    &__svg-wave {
        stroke: var(--c-white);
        stroke-width: 2px;
        fill: var(--c-menu-background-color);
    }
}
