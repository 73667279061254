@import "src/styles";

.flavours-section {
    position: relative;
    padding-block: 0 6rem;

    @include for-size(tablet-portrait-up) {
        padding-block: 6rem;
    }

    &__wrapper {
        position: relative;
        z-index: 20;

        @include for-size(tablet-portrait-up) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__title,
    &__description-wrapper {
        width: 100%;

        @include for-size(tablet-portrait-up) {
            width: calc(50% - 1rem);
        }
    }

    &__title {
        @extend %heading-2;

        color: var(--c-red);
    }

    &__title-part {
        display: block;
    }

    &__description-wrapper {
        margin-block-start: 1.5rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 0;
        }
    }

    &__description-empty-space {
        @extend %heading-2;

        display: none;

        @include for-size(tablet-portrait-up) {
            content: " ";
            display: block;
            padding-block-start: 1em;
        }
    }

    &__carousel {
        position: relative;
        margin-block-start: 3rem;
        z-index: 20;
    }

    &__personality-test-cta-wrapper {
        margin-block-start: 5rem;
    }
}
