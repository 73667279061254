@import "src/styles";

.social-navigation {
    --social-navigation-scale: 1;

    &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1.5rem;
    }

    &__item {
        list-style-type: none;
    }

    &__link {

        &:hover,
        &:focus {
            --social-navigation-scale: 1.1;
        }
    }

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(2.5rem);

        color: var(--c-primary);
        background-color: var(--c-white);
        box-shadow: var(--box-shadow);
        transition: scale .1s ease;
        scale: var(--social-navigation-scale);
    }
}
