@import "src/styles";

.table-slice {
    padding-block: 3rem;

    &__table-wrapper {
        width: fit-content;
        padding-block: .5rem;
        padding-inline: 1rem;
        border-radius: var(--border-radius);
        background-color: var(--c-white);
    }

    &__table {
        border-collapse: collapse;
    }

    &__header {
        font-weight: var(--fw-medium);
        color: var(--c-accent);
    }

    &__header &__cell {
        padding-block: 1rem;
    }

    &__body &__row {
        @extend %label;

        border-block-start: 1px solid rgba(var(--c-black-rgb), .25);

        &:first-of-type {
            border: none;
        }
    }

    &__cell {
        width: 18rem;
        padding-block: .5rem;
        padding-inline: 0 .5rem;

        & + & {
            padding-inline: .5rem 0;
        }
    }
}
