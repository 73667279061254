@import "src/styles";

.related-article-item {
    position: relative;
    padding: .625rem;
    border-radius: var(--border-radius);
    background-color: var(--c-white);
    box-shadow: var(--box-shadow);

    &__header {
        position: relative;
        z-index: 10;
    }

    &__thumbnail {
        position: relative;
        z-index: 10;
        width: 100%;
        aspect-ratio: 16 / 10;
        object-fit: cover;
        border-radius: var(--border-radius);
    }

    &__text-wrapper {
        margin-block-start: 1.5rem;
        padding-inline-start: .25rem;
        line-height: 1.125;
    }

    &__link {
        text-decoration: none;
        color: currentColor;

        &::after {
            @extend %click-extend;

            z-index: 20;
            content: "";
        }
    }

    &__title {
        font-family: var(--f-secondary);
        font-size: 1.5rem;
        text-transform: lowercase;
    }

    &__date {
        margin-block-start: 1rem;
        font-weight: var(--fw-medium);
        line-height: 2;
    }

    &__intro {
        margin-block-start: 1.5rem;
    }
}
