%button {
    @extend %flex-align-center;

    margin: 0;
    padding-block: .75rem;
    padding-inline: 1.5rem;
    font: inherit;
    font-size: 1rem;
    font-weight: var(--fw-medium);
    line-height: 1;
    letter-spacing: 1px;
    color: var(--c-white);
    cursor: pointer;
    border: none;
    border-radius: var(--border-radius-large);
    background-color: var(--c-primary);

    &:disabled {
        color: var(--c-white);
        cursor: not-allowed;
        background-color: var(--c-grey);
    }
}

%button--white {
    @extend %button;

    color: var(--c-primary);
    background-color: var(--c-white);
    box-shadow: var(--box-shadow);
}

%button--no-background {
    background: none;

    &[href]:hover,
    &[href]:focus {
        background: none;
    }

    &:enabled:hover,
    &:enabled:focus {
        background: none;
    }
}

%button--call-to-action {
    @extend %button--no-background;

    display: flex;
    gap: 1rem;
    width: fit-content;
    padding: 0;
    font-family: var(--f-secondary);
    font-size: 1.5rem;
    text-transform: lowercase;
    letter-spacing: 0;
    color: var(--c-accent);
    border-radius: 0;

    &:disabled {
        color: var(--c-grey);
        background-color: transparent;
    }

    &::after {
        content: "";
        width: 3.75rem;
        height: 2px;
        background: linear-gradient(90deg, transparent, currentColor);
    }
}
