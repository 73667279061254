@import "src/styles";

.vacancy-overview-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    position: relative;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        gap: 1.5rem;
    }

    &__icon {
        height: 5rem;
        color: var(--c-blue);
    }

    &__link-button {
        @extend %button--call-to-action;

        &::before {
            @extend %click-extend;

            content: "";
        }
    }

    &__meta-wrapper {
        display: flex;
        gap: 1.5rem;
        margin-block-start: 1.5rem;
    }

    &__meta-label {
        @extend %label;
    }
}
