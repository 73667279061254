@import "src/styles";

.usp-carousel {
    position: relative;
    width: 100%;

    &__wrapper-sizer {
        position: relative;
    }

    &__wrapper-sizer-inner {
        @extend %click-extend;
    }

    &__scroller-wrapper {
        display: flex;
    }

    &__button-wrapper {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 2rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: center;
            gap: 4rem;
        }
    }

    &__link-button {
        @extend %button--call-to-action;
    }

    &__control-wrapper {
        display: flex;
        gap: .5rem;
    }

    &__control-button {
        @include circle(3rem);

        padding: 0;
        color: var(--c-primary);
        background-color: var(--c-white);
        box-shadow: var(--box-shadow);

        &:enabled:hover,
        &:enabled:focus {
            color: var(--c-white);
            background-color: var(--c-primary);
        }
    }

    &__scroller {
        --sb-track-color: var(--c-background);
        --sb-thumb-color: rgba(var(--c-cyan-rgb), .5);

        position: relative;
        padding-block: 2rem 1rem;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scroll-snap-align: center;
        scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);

        @include for-size(tablet-landscape-up) {
            overflow: visible;
        }
    }

    &__list-wrapper {
        width: fit-content;
    }

    &__list {
        display: flex;
        gap: 1.875rem;
        width: fit-content;

        @include for-size(phone-only) {
            gap: 1.25rem;
        }

        @include for-size(tablet-landscape-up) {
            padding-inline: 2.5rem;
        }
    }

    &__list-sizer {
        position: absolute;
        opacity: 0;
    }

    &__list-item {
        width: 15rem;
        scroll-snap-align: center;

        @include for-size(phone-only) {
            width: calc(100vw - var(--wrapper-padding) * 3);
        }
    }
}
