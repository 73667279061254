@import "src/styles";

.partner-grid {

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(4, 1fr);
        }

        @include for-size(desktop-up) {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    &__item {
        @extend %flex-align-center;

        padding: 1rem;
        background-color: var(--c-background);
    }

    &__picture {
        max-width: 8rem;
        mix-blend-mode: multiply;
    }
}
