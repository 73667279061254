@import "src/styles";

$component: "usp-carousel-stat";

.#{$component} {
    height: fit-content;
    padding: .625rem;
    overflow: clip;
    color: var(--c-orange);
    border-radius: var(--border-radius);
    background-color: var(--c-white);
    box-shadow: .125rem .125rem 2.5rem 0 rgba(var(--c-black-rgb), .06);

    &:not(.#{$component}--is-initialized) {
        opacity: 0;
    }

    &:not(.#{$component}--is-active) {
        color: var(--c-blue);
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
        padding: 1.5rem;
        overflow: clip;
        border-radius: .375rem;
        border: 1px solid var(--c-blue);
    }

    &__heading {
        @extend %flex-align-center;

        flex-direction: column;
    }

    &__icon,
    &__icon-wrapper {
        width: 7rem;
        height: 5rem;
    }

    &__title {
        @extend %heading-4;

        margin-block-start: 1.5rem;
        text-align: center;
        hyphens: auto;
    }

    &__description {
        margin-block-start: 1.5rem;
        font-size: 1rem;
        text-align: center;
        line-height: 2;
        color: var(--c-black);
    }
}
