@import "src/styles";

.flavours-carousel {

    @include for-size(tablet-portrait-up) {
        display: flex;
        align-items: center;
        height: 25rem;
    }

    &__wrapper {
        position: relative;

        @include for-size(tablet-portrait-up) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
        }
    }

    &__buttons-wrapper {
        display: flex;
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: var(--wrapper-padding);
        gap: .5rem;

        @include for-size(tablet-portrait-up) {
            inset-block-start: 1rem;
            inset-inline-start: unset;
            inset-inline-end: 1rem;
        }
    }

    &__button {
        @include circle(3rem);

        padding: 0;
        color: var(--c-primary);
        background-color: var(--c-white);
        box-shadow: var(--box-shadow);

        &:enabled:hover,
        &:enabled:focus {
            color: var(--c-white);
            background-color: var(--c-primary);
        }
    }

    &__flavours-wrapper {
        position: relative;
        width: 100%;
        height: 50vh;
        min-height: 12.5rem;

        @include for-size(tablet-portrait-up) {
            width: 15rem;
            height: 15rem;
        }
    }

    &__flavours {
        @extend %click-extend;

        display: flex;
        align-items: center;
        width: calc(100% + (2 * var(--wrapper-padding)));
        height: 100%;
        margin-left: negative(var(--wrapper-padding)); // specifically needed for left side of screen
        padding-inline: 50%;
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
        scroll-snap-align: center;

        @include for-size(tablet-portrait-up) {
            flex-direction: row-reverse;
            left: unset;
            right: 0;
            width: max-content;
            height: fit-content;
            margin-inline-start: unset;
            padding-inline: unset;
            overflow: unset;

            @include rtl {
                left: 0;
                right: unset;
                margin-inline-end: unset;
            }
        }
    }

    &__flavour {
        min-width: 80vw;
        height: 75%;
        scroll-snap-align: center;

        @include for-size(phone-only) {
            min-width: 100vw;
        }

        @include for-size(tablet-portrait-up) {
            width: 18rem;
            min-width: unset;
            height: 18rem;
            margin-inline-end: 0;
        }
    }

    &__flavour-image {

        @include for-size(tablet-portrait-up) {
            translate: 2.5rem 0;

            @include rtl {
                translate: 0 0;
            }
        }
    }

    &__content-wrapper {
        margin-block-start: 1rem;

        @include for-size(tablet-portrait-up) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            width: 23rem;
            height: 22rem;
            margin-block-start: 0;
            margin-inline-start: 4rem;
        }

        @include for-size(tablet-landscape-up) {
            width: 28rem;
            margin-inline-start: 6rem;
        }
    }

    &__title {
        @extend %heading-2;

        margin-block-start: 1rem;
        font-size: 2.5rem;
        color: var(--c-pink);

        @include for-size(phone-only) {
            width: 0;
            word-break: keep-all;
        }

        @include for-size(tablet-portrait-up) {
            font-size: 3rem;
        }
    }

    &__discover-button {
        @extend %button--call-to-action;

        margin-block-start: 1rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 1.5rem;
        }
    }

    &__flavours-block-end {
        display: none;
        position: relative;
        margin-inline-start: 1rem;

        @include for-size(tablet-portrait-up) {
            display: block;
        }
    }
}
