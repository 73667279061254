@font-face {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../typography/Gotham-Book.otf") format("opentype");
}

@font-face {
    font-family: "Gotham";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("../typography/Gotham-BookIta.otf") format("opentype");
}

@font-face {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("../typography/Gotham-Medium.otf") format("opentype");
}

@font-face {
    font-family: "Appetite";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../typography/Appetite-New-Regular.ttf") format("truetype");
}
