@import "../helpers";

%label {
    font-size: .75rem;
    font-weight: var(--fw-medium);
    letter-spacing: 1.5px;
    color: var(--c-black);
}

%heading {
    font-family: var(--f-secondary);
    font-weight: var(--fw-medium);
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    text-transform: lowercase;
    text-wrap: balance;
    word-break: break-word;
    hyphens: auto;
    color: var(--c-black);
}

%heading-1 {
    @extend %heading;

    font-size: 6.5rem;

    @include for-size(tablet-portrait-up) {
        font-size: 9.75rem;
    }

    @include for-size(tablet-landscape-up) {
        font-size: 16.25rem;
    }
}

%heading-2 {
    @extend %heading;

    font-size: 4rem;

    @include for-size(phone-only) {
        font-size: 3rem;
    }

    @include for-size(tablet-portrait-up) {
        font-size: 6rem;
        line-height: .88;
    }

    @include for-size(tablet-landscape-up) {
        font-size: 6.75rem;
    }
}

%heading-3 {
    @extend %heading;

    font-size: 2.5rem;

    @include for-size(tablet-portrait-up) {
        font-size: 2.75rem;
    }

    @include for-size(tablet-landscape-up) {
        font-size: 3rem;
    }
}

%heading-4 {
    @extend %heading;

    font-size: 1.25rem;

    @include for-size(tablet-landscape-up) {
        font-size: 1.5rem;
    }
}
