.vacancy-content {

    &__body {
        width: 100%;
        margin-block-start: 5rem;
    }

    &__footer {
        margin-block-start: 5rem;
    }
}
