@keyframes spin {
    to { rotate: 1turn; }
}

@keyframes pulse {
    to { scale: .5; }
}

@keyframes undo-pulse {
    to { scale: 2; }
}

@keyframes scale-in {
    from { scale: .1; }
    to { scale: 1 }
}

@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes float-up {
    0% { translate: 0 0; }
    50% { translate: 0 -5%; }
    100% { translate: 0 0; }
}

@keyframes float-down {
    0% { translate: 0 0; }
    50% { translate: 0 5%; }
    100% { translate: 0 0; }
}

@keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes fluid-scale {
    30% { scale: .95; }
    50% { scale: 1.025; }
    70% { scale: .9875; }
    100% { scale: 1; }
}

@keyframes marquee {
    from { translate: 0 0; }
    to { translate: -100% 0; }
}

@keyframes skeleton-load {
    from { translate: -100% 0; }
    to { translate: 100% 0; }
}

@keyframes clip-path-reset {
    to { clip-path: inset(0); }
}

@keyframes personality-question-fade {
    0%, 100% {
        opacity: 1;
        translate: 0 0;
    }
    49% {
        opacity: 0;
        translate: var(--fade-offset-start) 0;
    }
    51% {
        opacity: 0;
        translate: var(--fade-offset-end) 0;
    }
}
