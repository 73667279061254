@import "src/styles";

.slice-renderer {
    font-size: 1rem;
    line-height: 1.5;

    &__slice {

        & + & {
            margin-block-start: 5rem;
        }
    }

    &__text-slice-html {
        @extend %html-format;

        max-width: 48rem;
        word-break: break-word;

        & > * + * {
            margin-block-start: 1.5rem;
        }

        h2 {
            @extend %heading-3;

            & + * {
                margin-block-start: 1.5rem;
            }
        }

        h3 {
            @extend %heading-4;

            & + * {
                margin-block-start: 1rem;
            }
        }
    }

    &__centered-text-slice,
    &__clean-hub-section-slice,
    &__image-slice,
    &__page-preview-slice,
    &__two-column-text-slice,
    &__video-slice {
        margin-block: 5rem;

        @include for-size(tablet-portrait-up) {
            margin-block: 7.5rem;
        }
    }

    &__vacancy-overview-slice {
        margin-block: 5rem;

        @include for-size(tablet-portrait-up) {
            margin-block: 10rem 7.5rem;
        }
    }
}
