@import "src/styles";

$component: "menu-sidebar";

.#{$component} {
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    pointer-events: none;
    backdrop-filter: blur(0px);
    transition: backdrop-filter .3s ease;

    &--is-expanding {
        pointer-events: all;
        backdrop-filter: blur(10px);

        .#{$component}__bubble {
            translate: 60% -22.5%;
            scale: 1;

            @include rtl {
                translate: -60% -22.5%;
            }
        }

        .#{$component}__top-wrapper {
            opacity: 1;
        }
    }

    &__nav {
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        z-index: 20;
        width: 100%;
        height: 100%;
        padding-block: 0 2rem;
        padding-inline: 1.5rem;
        overflow-y: auto;

        @include for-size(tablet-portrait-up) {
            max-width: 32rem;
            padding-block: 0 2rem;
            padding-inline: 4rem;
        }
    }

    &__top-wrapper {
        display: flex;
        align-items: center;
        gap: 1rem;
        height: var(--menu-height);
        padding-block: 2rem;
        transition: opacity .1s ease;
        opacity: 0;
    }

    &__home-link {
        margin-inline-end: auto;
    }

    &__logo {
        color: var(--c-white);
        height: 3rem;

        @include for-size(tablet-portrait-up) {
            display: none;
        }
    }

    &__find-button {
        padding-block: 1rem;
        padding-inline: 1.5rem;
        color: var(--c-primary);
        background-color: var(--c-white);
        box-shadow: var(--box-shadow);

        &:hover,
        &:focus {
            color: var(--c-white);
        }
    }

    &__find-button-fill {
        background-color: var(--c-primary);
        opacity: 1;
    }

    &__close-button {
        @extend %button--no-background;

        padding-inline: 0;
        border-radius: 0;
    }

    &__close-icon {
        @include square(1.5rem);
    }

    &__child-wrapper {
        margin-block-start: 2.5rem;
    }

    &__tab-close-button {
        @extend %button--no-background;

        margin-inline-start: auto;
        margin-block-start: 2.5rem;
        pointer-events: none;
        transition: opacity .1s ease;
        opacity: 0;

        &:focus {
            opacity: 1;
        }
    }

    &__bubble {
        @include circle(200vh);

        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        z-index: 10;
        background-color: var(--c-green);
        transition: translate .3s ease, scale .3s ease;
        translate: 100% -50%;
        scale: 0;

        @include rtl {
            translate: -100% -50%;
        }

        @include for-size(tablet-portrait-up) {
            @include circle(80rem);
        }
    }
}
