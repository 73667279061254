@import "src/styles";

$component: "menu";

.#{$component} {
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    z-index: 100;
    width: 100%;
    height: var(--menu-height);
    pointer-events: none;

    &--is-compact {

        .#{$component}__background {
            translate: 0 0;
        }

        .#{$component}__buttons-wrapper {
            translate: 0 -1.5rem;
        }

        .#{$component}__home-link {
            translate: 0 -1rem;
            scale: .8;

            @include for-size(tablet-portrait-up) {
                scale: .65;
            }
        }
    }

    &__background-wrapper {
        @extend %click-extend;
        @extend %flex-align-center;

        z-index: 10;
        inset-block-start: 1rem;
        inset-inline-start: 8rem;

        @include for-size(phone-only) {
            inset-inline-start: 2rem;
            inset-block-start: 2rem;
        }

        @include for-size(tablet-portrait-up) {
            inset-block-start: 2rem;
            inset-inline-start: 7rem;
        }

        @include for-size(tablet-landscape-up) {
            inset-block-start: 0;
            inset-inline-start: 3.5rem;
        }

        @include for-size(laptop-up) {
            inset-inline-start: 0;
        }
    }

    &__background {
        min-width: 100rem;
        max-width: 100rem;
        height: 100%;
        translate: 0 -100%;
        transition: translate .5s ease-out;
    }

    &__wrapper {
        position: relative;
        z-index: 20;
        display: flex;
        align-items: center;
        gap: 1rem;
        height: 100%;
        padding-block: 1.5rem;

        @include for-size(tablet-portrait-up) {
            padding-block: 2rem;
        }
    }

    &__home-link {
        margin-inline-end: auto;
        pointer-events: all;
        transition: translate .25s ease-out, scale .25s ease-out;
        transform-origin: 0 50%;

        @include rtl {
            transform-origin: 100% 50%;
        }
    }

    &__logo {
        color: var(--c-primary);
        height: 3rem;

        @include for-size(tablet-portrait-up) {
            height: 6rem;
        }
    }

    &__buttons-wrapper {
        display: flex;
        gap: 1rem;
        transition: translate .25s ease-out;
    }

    &__find-button,
    &__i18n-button,
    &__hamburger-button {

        &:hover,
        &:focus {
            color: var(--c-white);
        }
    }

    &__find-button {
        @extend %button--white;

        display: none;
        padding-block: 1rem;
        padding-inline: 1.5rem;
        pointer-events: all;

        @include for-size(tablet-portrait-up) {
            display: flex;
        }
    }

    &__hamburger-button {
        @extend %button--white;

        padding-block: .75rem;
        padding-inline: 1rem;
        pointer-events: all;
    }

    &__hamburger-icon {
        height: 1.5rem;
        color: currentColor;

        @include rtl {
            scale: -1 1;
        }
    }

    &__i18n-button {
        @extend %button--white;

        padding: .5rem;
        pointer-events: all;
    }

    &__i18n-icon {
        height: 2rem;
        color: currentColor;
    }

    &__liquid-button-fill {
        background-color: var(--c-primary);
        opacity: 1;
    }
}
