.spotify-playlist-background {

    &__gradient-start {
        stop-color: rgba(var(--c-cyan-rgb), .1);
    }

    &__gradient-end {
        stop-color: rgba(var(--c-sky-rgb), .5);
    }

    &__svg-wave {
        stroke: var(--c-cyan);
        stroke-width: .5px;
    }
}
