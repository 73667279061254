.faq-skeletons {

    &__intro-wrapper {
        max-width: 46rem;
    }

    &__title {
        font-size: 1.75rem;
    }

    &__intro {
        margin-block-start: 1.5rem;
    }

    &__intro-line {

        & + & {
            margin-block-start: .75rem;
        }
    }

    &__list {
        max-width: 52rem;
        margin-block-start: 2rem;
    }

    &__item {

        & + & {
            margin-block-start: 1rem;
        }
    }

    &__question {
        width: 100%;
        height: 4rem;
        border-radius: var(--border-radius);
    }
}
