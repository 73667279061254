@import "src/styles";

.gym-locator {
    @extend %storepoint-embed;

    &__notice {
        @extend %flex-align-center;

        width: 100%;
        height: 75vh;
        padding-inline: var(--wrapper-padding);
    }

    &__notice-card {
        position: relative;
        z-index: 20;
        padding: 1.5rem;
        width: 100%;
        max-width: 32rem;
        border-radius: var(--border-radius);
        background-color: var(--c-white);
    }

    &__notice-title {
        @extend %heading-4;
    }

    &__notice-description {
        margin-block-start: 1.5rem;
    }

    &__notice-button {
        margin-block-start: 1.5rem;
    }

    &__placeholder-map {
        @extend %click-extend;

        z-index: 10;
        object-fit: cover;
        filter: blur(5px);
    }

    &__embed {
        position: relative;
        z-index: 1;
    }
}
