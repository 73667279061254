@import "src/styles";

$component: playground-section-button;

.#{$component} {
    position: relative;
    aspect-ratio: .75;
    transform-origin: 50% 50%;
    background-color: transparent;

    @include for-size(tablet-portrait-up) {
        aspect-ratio: .8;
        transition: scale .2s ease-out, rotate .2s ease-out;
    }

    &:hover,
    &:focus {

        .#{$component}__icon-wrapper {
            opacity: 1;
        }

        .#{$component}__icon {
            width: 1.125rem;
            height: 1.125rem;
        }
    }

    &--is-playing {

        .#{$component}__icon-wrapper {
            inset-block-start: 1.5rem;
            inset-inline-end: 1.5rem;
            width: 2.5rem;
        }
    }

    &__wrapper {
        @extend %click-extend;

        border: 1px solid var(--c-white);
        border-radius: .5rem;
        background-color: var(--c-white);
        box-shadow: 5px 5px 10px 2px rgba(var(--c-black-rgb), .05);

        @include for-size(tablet-portrait-up) {
            aspect-ratio: .8;
            border-radius: 1rem;
        }
    }

    &__picture,
    &__video-wrapper {
        @extend %click-extend;

        height: calc(100% - 2rem);
    }

    &__picture-image,
    &__video {
        position: absolute;
        inset-block-start: 1vw;
        inset-inline-start: 1vw;
        border-radius: .5rem;
        width: calc(100% - 2vw);
        height: calc(100% - 2vw);
        object-fit: cover;

        @include for-size(tablet-portrait-up) {
            inset-block-start: 1rem;
            inset-inline-start: 1rem;
            width: calc(100% - 2rem);
            height: calc(100% - 2rem);
        }
    }

    &__icon-wrapper {
        @extend %flex-align-center;

        position: absolute;
        inset-block-start: calc(50% - 2rem);
        inset-inline-end: calc(50% - 2rem);
        border-radius: 50%;
        width: 4rem;
        aspect-ratio: 1;
        color: var(--c-primary);
        background: var(--c-white);

        @include for-size(tablet-portrait-up) {
            opacity: 0;
            transition: opacity .2s ease-out;
        }
    }

    &__icon {
        width: 1.25rem;
        height: 1.25rem;
    }

    &__caption {
        position: absolute;
        width: 100%;
        inset-block-end: .75rem;
        inset-inline-start: 0;
        padding-inline: .5rem;
        font-size: .6875rem;
        font-weight: var(--fw-regular);
        color: var(--c-black);
        text-align: start;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @include for-size(tablet-portrait-up) {
            inset-block-end: 1.125rem;
            padding-inline: 1rem;
        }
    }
}