.contact-section-background {
    width: 100%;
    height: 100%;

    &__gradient-start {
        stop-color: rgba(var(--c-cyan-rgb), 1);
    }

    &__gradient-end {
        stop-color: rgba(var(--c-cyan-rgb), 0);
    }

    &__svg-wave {
        stroke: var(--c-cyan);
        stroke-width: 1px;
    }
}