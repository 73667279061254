@import "src/styles";

.faq-page {
    padding-block-start: calc(var(--menu-height) + 2.5rem);

    &__playground-section-wrapper {
        overflow: clip;
        margin-block-start: 6rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 12rem;
        }
    }

    &__playground-section {
        padding-block: 0;
    }
}
