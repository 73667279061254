@import "src/styles";

$component: "nutrition-table";

.#{$component} {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 2rem;
    font-size: .875rem;
    font-weight: var(--fw-medium);
    letter-spacing: 1px;
    border-radius: var(--border-radius-large);
    background-color: var(--c-white);

    &--is-europe {

        .#{$component}__header-row .#{$component}__cell,
        .#{$component}__cell {
            width: calc(100% - 14rem);
        }

        .#{$component}__cell ~ .#{$component}__cell {
            width: 7rem;
        }
    }

    &__scroll-hint-wrapper {
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 2rem;
        height: 2rem;
    }

    &__scroll-hint {
        @extend %label;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: .5rem;
        margin-block-start: 1rem;
        line-height: 1;
        color: var(--c-primary);
        opacity: 0;

        &--is-at-start {
            opacity: 1;
        }

        &--is-at-end {
            flex-direction: row-reverse;
            opacity: 1;

            .#{$component}__scroll-hint-icon {
                scale: -1 1;

                @include rtl {
                    scale: 1 1;
                }
            }
        }
    }

    &__scroll-hint-icon {
        height: .75rem;

        @include rtl {
            scale: -1 1;
        }
    }

    &__header-row {
        font-size: 1rem;
        letter-spacing: 0;
        color: var(--c-purple);
    }

    &__loading-spinner-wrapper {
        @extend %flex-align-center;

        width: 100%;
        flex-grow: 1;
    }

    &__row {
        height: 2rem;
    }

    &__cell {
        width: calc(100% - 3rem);
        min-width: unset;
        max-width: unset;

        &:first-child {
            position: sticky;
            inset-inline-start: 0;
            padding-inline-end: .5rem;
            background-color: var(--c-white);
        }

        &:nth-child(2) {
            width: 3rem;
        }

        & + & .#{$component}__cell-content {
            width: 100%;
            padding: 0;
            text-align: end;
        }
    }

    &__cell:first-child &__cell-content {
        display: flex;
        gap: .125rem;
    }

    &__cell-content {
        padding-inline-end: 0;
    }

    &__header-row &__cell {
        width: 50%;
    }

    &__header-row &__cell-content {
        display: unset;
    }
}
