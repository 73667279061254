@import "src/styles";

$component: "toggle";

.#{$component} {
    --toggle-size: 1.75rem;

    display: flex;
    align-items: center;
    gap: 1rem;
    width: fit-content;
    cursor: pointer;

    &__input {
        position: absolute;
        z-index: 10;
        opacity: 0;

        &:focus-visible ~ .#{$component}__handle-wrapper .#{$component}__handle {
            outline: 2px solid var(--c-accent);
        }

        &:checked ~ .#{$component}__handle-wrapper {

            .#{$component}__handle,
            .#{$component}__accent {
                translate: 50% 0;

                @include rtl {
                    translate: -50% 0;
                }
            }
        }

        &:disabled ~ .#{$component}__handle-wrapper {
            --c-accent: var(--c-grey);

            cursor: not-allowed;
        }
    }

    &__label,
    &__handle-wrapper {
        position: relative;
        z-index: 20;
    }

    &__label {
        @extend %label;
    }

    &__handle-wrapper {
        @extend %input;

        display: block;
        width: calc(var(--toggle-size) * 1.5);
        height: var(--toggle-size);
        padding: 0;
        border-radius: var(--toggle-size);
        box-shadow: inset var(--box-shadow);
        background-color: rgba(var(--c-black-rgb), .05);
    }

    &__handle {
        @extend %draggable;
        @include circle(var(--toggle-size));

        display: block;
        position: relative;
        inset-block-start: -1px;
        z-index: 20;
        transition: translate .1s ease;
        scale: 1.05;
        border: 1px solid rgba(var(--c-black-rgb), .2);
    }

    &__accent-wrapper {
        @extend %click-extend;

        z-index: 10;
        overflow: clip;
        border-radius: inherit;
    }

    &__accent {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: negative(var(--toggle-size) / 2);
        z-index: 0;
        height: 100%;
        aspect-ratio: 1;
        background-color: var(--c-accent);
        transition: translate .1s ease;
    }
}
