.prismic-page-content {
    overflow-x: clip;

    &__header {
        position: relative;
        z-index: 10;
    }

    &__slice-renderer {
        position: relative;
        z-index: 20;
        margin-block-start: 2.5rem;
    }
}
