@import "src/styles";

.personality-test-cta {
    display: flex;
    justify-content: flex-end;

    @include for-size(tablet-portrait-up) {
        padding-inline-end: 8rem;
    }

    &__wrapper {
        max-width: 28rem;
    }

    &__title {
        @extend %heading-3;

        color: var(--c-green);
    }

    &__description {
        margin-block-start: 2rem;
    }

    &__link-button {
        @extend %button--call-to-action;

        margin-block-start: 4rem;
    }
}
