.vacancy-renderer-skeletons {

    &__paragraph {

        & + & {
            margin-block-start: 2.5rem;
        }
    }

    &__subtitle {
        height: 1.5rem;
    }

    &__text {
        margin-block-start: 1rem;

        & + & {
            margin-block-start: .5rem;
        }
    }
}
