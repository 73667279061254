@import "src/styles";

.welcome-section {
    position: relative;
    width: 100%;
    height: 100%;
    padding-block-start: var(--menu-height);
    padding-block-end: 5rem;
    overflow: hidden;

    @include for-size(tablet-portrait-up) {
        padding-block-start: 0;
    }

    &__wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column-reverse;
        position: relative;
        padding: 0;
        width: 100%;
        height: 100%;

        @include for-size(tablet-portrait-up) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding-block-start: 0;

            @media (max-aspect-ratio: 5 / 2) {
                padding-block-start: 3.75rem;
            }

            @media (max-aspect-ratio: 4 / 2) {
                padding-block-start: 7.5rem;
            }
        }
    }

    &__picture-wrapper,
    &__content-wrapper {
        position: relative;
        width: 100%;

        @include for-size(tablet-portrait-up) {
            height: 100%;
        }
    }

    &__content-wrapper {
        max-width: 25rem;
        padding-inline: var(--wrapper-padding);
        z-index: 10;

        @include for-size(tablet-portrait-up) {
            padding-block: 6rem 4rem;
            max-width: inherit;
            height: 100%;
        }

        @include for-size(tablet-landscape-up) {
            padding-block-start: 10rem;
        }
    }

    &__fluid-title-wrapper {
        width: 100%;
    }

    &__fluid-title-flexbox {
        @extend %flex-align-center;

        width: 100%;
        height: 100%;
    }

    &__fluid-title {
        width: 100%;
        aspect-ratio: 31.25 / 20;

        @include for-size(tablet-portrait-up) {
            width: 120%;
            margin-inline-start: -20%;
        }

        @include for-size(laptop-up) {
            margin-inline-start: -35%;
        }

        @include rtl {

            @include for-size(tablet-portrait-up) {
                margin-inline-start: 0;
            }

            @include for-size(laptop-up) {
                margin-inline-start: -20%;
            }
        }
    }

    &__description {
        @extend %html-format;

        margin-block-start: 4rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 5rem;
        }
    }

    &__call-to-action {
        @extend %button--call-to-action;

        display: flex;
        margin-block-start: 4rem;
        margin-block-end: 2rem;
    }

    &__picture-wrapper {
        display: none;

        @include for-size(tablet-portrait-up) {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-inline-start: 2rem;
            z-index: 20;
            pointer-events: none;
        }

        @include for-size(tablet-landscape-up) {
            padding-inline-start: 0;
        }
    }

    &__picture {
        max-width: unset;

        &--desktop {
            @extend %click-extend;

            display: flex;
            height: calc(100% + 2rem);
            object-fit: contain;

            @include for-size(phone-only) {
                width: 120%;
                height: calc(100% + 1rem);
                max-height: calc(100% + 3rem);
                margin-inline-start: -10%;
            }

            @include for-size(tiny-screen-only) {
                width: 140%;
                max-height: calc(100% + 2rem);
                margin-inline-start: -20%;
            }

            @include for-size(tablet-portrait-up) {
                margin-block-start: -5rem;
                width: 130%;
                height: 100%;
            }

            @include for-size(tablet-landscape-up) {
                margin-block-start: 0;
            }

            @include for-size(laptop-up) {
                margin-inline-start: -11%;
            }
        }

        &--mobile {
            display: flex;
            position: relative;
            z-index: 20;
            margin-block-start: -4rem;
            margin-inline-start: -20%;
            width: 140vw;
            aspect-ratio: 12 / 17;

            @include for-size(tablet-portrait-up) {
                display: none;
            }
        }
    }

    &__picture-image {
        object-fit: contain;
    }

    &__wave-background {
        position: absolute;
        inset-block-end: 30%;
        inset-inline-start: -10%;
        width: 140%;
        height: 50%;

        @include for-size(tablet-portrait-up) {
            width: 120%;
        }

        @include for-size(tablet-landscape-up) {
            inset-inline-start: 0;
            width: 100%;
        }

        @include rtl {
            scale: -1 1;
        }
    }

    &__solid-background {
        position: absolute;
        inset-block-end: 0;
        width: 100%;
        height: 30%;
        background-color: var(--c-sky);
    }
}
