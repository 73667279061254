.usp-carousel-list-item {
    position: relative;
    list-style: none;

    &__sizer {
        opacity: 0;
        cursor: pointer;
    }

    &__stat {
        position: absolute;
        inset-block-start: 0;
        width: 100%;
    }
}
