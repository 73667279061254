.article-content-skeletons {

    &__breadcrumbs {
        width: 100%;
        max-width: 16rem;
    }

    &__title-wrapper {
        margin-block-start: 2rem;
    }

    &__title {
        height: 3rem;

        & + & {
            margin-block-start: 1.5rem;
        }
    }

    &__body {
        margin-block-start: 4rem;
    }

    &__renderer {
        width: 100%;
        max-width: 46rem;
    }
}
