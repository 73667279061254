@import "src/styles";

.cookie-settings {
    position: fixed;
    inset-block-end: 0;
    inset-inline-end: 0;
    z-index: 10;
    width: fit-content;
    padding: var(--wrapper-padding);
    pointer-events: none;

    &__card {
        max-width: 30rem;
        max-height: calc(100vh - var(--menu-height));
        max-height: calc(100dvh - var(--menu-height));
        overflow-y: auto;
        padding: 1.5rem;
        pointer-events: all;
        border-radius: var(--border-radius);
        background-color: var(--c-white);
        box-shadow: var(--box-shadow);
        transition: translate .3s ease, opacity .3s ease;
        translate: calc(100% + var(--wrapper-padding)) 0;
        opacity: 0;

        @include rtl {
            translate: negative(calc(100% + var(--wrapper-padding))) 0;
        }

        &--is-revealed {
            translate: 0 0;
            opacity: 1;

            @include rtl {
                translate: 0 0;
                opacity: 1;
            }
        }
    }

    &__title {
        @extend %heading-4;

        line-height: 1.5;
    }

    &__description {
        margin-block-start: 1rem;
        font-size: .875rem;

        @include for-size(tablet-portrait-up) {
            font-size: 1rem;
        }
    }

    &__link {
        display: block;
        width: fit-content;
        margin-block-start: .5rem;
        font-size: .875rem;
        color: var(--c-primary);

        @include for-size(tablet-portrait-up) {
            font-size: 1rem;
        }
    }

    &__toggle-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-block-start: 1.5rem;
    }

    &__button-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1.5rem;
        margin-block-start: 1.5rem;
    }

    &__button {
        width: fit-content;
        padding-block: 1rem;
        padding-inline: 2rem;
        font-size: .875rem;

        &--is-reject {
            @extend %button--white;
        }
    }
}
