@import "src/styles";

.fixed-navigation {
    display: none;
    flex-direction: column;
    gap: 14rem;
    position: fixed;
    z-index: 200;
    inset-block-end: 0;
    inset-inline-start: 0;
    padding-inline-start: max(var(--wrapper-padding), calc((100vw - var(--wrapper-width)) / 4));
    pointer-events: none;

    @include for-size(tablet-landscape-up) {
        display: flex;
        justify-content: center;
        height: 100%;
    }

    &__list {
        margin: 0;
        padding: 0;
        pointer-events: all;
    }

    &__item {
        list-style-type: none;
    }

    &__discover-button {
        @extend %label;
        @extend %button--no-background;

        display: flex;
        align-items: center;
        position: absolute;
        bottom: 0;
        gap: 1.25rem;
        padding-inline-start: 0;
        text-transform: lowercase;
        color: var(--c-blue);
        border-radius: 0;
        pointer-events: none;
        transition: opacity .3s ease;
        opacity: 0;
        transform-origin: left;
        rotate: -90deg;
        translate: 1.5rem 50%;

        @include rtl {
            transform-origin: right;
            rotate: 90deg;
            translate: -1.5rem 50%;
        }

        @media (min-aspect-ratio: 20 / 9) {
            display: none;
        }

        &::before {
            content: "";
            width: 6.75rem;
            height: 2px;
            background: linear-gradient(90deg, var(--c-blue), transparent);

            @include rtl {
                background: linear-gradient(-90deg, var(--c-blue), transparent);
            }
        }

        &--is-revealed {
            pointer-events: all;
            opacity: 1;
        }
    }
}
