@import "src/styles";

.home-page {
    overflow-x: clip;

    &__section {

        &--welcome {
            height: fit-content;
            min-height: 35rem;
            padding-block-start: 0;

            @include for-size(tablet-portrait-up) {
                min-height: 43.75rem;
            }
        }

        &--hydration-station {
            position: relative;
            padding-block-start: 0;
        }

        &--flavours,
        &--usp,
        &--playground,
        &--gym-locator,
        &--contact {
            height: auto;
            min-height: unset;
            padding-block-start: 0;
        }

        &--flavours {
            margin-block-start: 2.5rem;
        }

        &--playground {

            @include for-size(tablet-portrait-up) {
                margin-block-start: -15rem;
            }
        }

        &--gym-locator {
            position: relative;
            padding-block-start: var(--menu-height);

            @include for-size(tablet-portrait-up) {
                padding-block-end: 6rem;
            }

            @include for-size(laptop-up) {
                padding-block-end: 8rem;
            }
        }
    }

    &__gym-locator-title {
        @extend %heading-2;

        position: relative;
        z-index: 30;
        margin-block-start: -2rem;
        padding-block-end: 2rem;
        color: var(--c-purple);
        text-align: center;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 0;
            padding-inline-end: 2.5rem;
        }

        @include for-size(laptop-up) {
            padding-inline-end: 0;
        }
    }

    &__hydration-station {
        position: relative;
        z-index: 20;
    }

    &__hydration-station-background {
        position: relative;
        z-index: 10;
    }

    &__gym-locator {
        position: relative;
        z-index: 1;
    }
}
