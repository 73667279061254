@import "src/styles";

.usp-grid {

    &__wrapper {
        position: relative;
        z-index: 20;
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        width: 100%;
        margin: 0;
        padding: 0;

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(3, 1fr);
            gap: 4rem;
        }

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &__item {
        list-style-type: none;
    }

    &__background {
        @extend %click-extend;
        @extend %flex-align-center;

        overflow: clip;
        pointer-events: none;
        transition: filter .3s ease;

        @include rtl {
            transform-origin: center;
            scale: -1 1;
        }

        &--is-blurry {
            filter: blur(2px);
        }
    }

    &__svg {
        width: 100%;
        height: 100%;
        min-width: 70rem;
        transform-origin: 0 0;
        scale: 1.35 1;
        translate: 0 10rem;
    }
}
