@import "src/styles";

.not-found-section {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;

    &--is-mobile &__video {
        z-index: 15;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &--is-mobile &__background {
        display: none;
    }

    &__wrapper {
        position: relative;
        z-index: 20;
    }

    &__reveal-wrapper {
        width: fit-content;
        clip-path: inset(0 100% 0 0);
        opacity: .25;

        @include rtl {
            clip-path: inset(0 0 0 100%);
        }

        &--is-revealed {
            animation: clip-path-reset var(--not-found-section-reveal-duration) linear forwards,
                fade-in var(--not-found-section-reveal-duration) ease forwards;
        }
    }

    &__title {
        @extend %heading-2;

        color: var(--c-white);
        text-shadow: 0 2px .5rem rgba(var(--c-black-rgb), .2);
    }

    &__link-button {
        @extend %button--call-to-action;

        margin-block-start: 1rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 2rem;
        }
    }

    &__video {
        position: absolute;
        z-index: 30;
        width: 100%;
        height: 100%;
        pointer-events: none;

        @include rtl {
            scale: -1 1;
        }
    }

    &__background {
        position: absolute;
        inset-block-end: 0;
        z-index: 10;
        width: 100%;
        min-width: 62.5rem;
        height: 100%;
        scale: -1 1;

        @include for-size(tablet-portrait-up) {
            width: 120%;
        }

        @include for-size(tablet-landscape-up) {
            width: 100%;
        }

        @include rtl {
            scale: 1 1;
        }
    }
}
