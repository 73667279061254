@import "src/styles";

$component: "faq-item";

.#{$component} {
    max-width: 52rem;
    list-style-type: none;

    &__details {
        width: 100%;

        &[open] {

            .#{$component}__summary {
                border-end-start-radius: 0;
                border-end-end-radius: 0;
            }

            .#{$component}__icon {
                scale: 1 -1;
            }
        }
    }

    &__summary {
        @extend %label;

        display: flex;
        align-items: center;
        padding-block: 1rem;
        padding-inline: 1.5rem;
        font-size: .875rem;
        line-height: 2;
        text-transform: lowercase;
        cursor: pointer;
        user-select: none;
        border-radius: var(--border-radius);
        background-color: var(--c-white);

        &::marker,
        &::-webkit-details-marker {
            display: none;
        }
    }

    &__icon {
        width: 1rem;
        margin-inline-start: auto;
        color: var(--c-accent);
        transition: scale .1s ease;
    }

    &__answer-wrapper {
        padding: 1.5rem;
        padding-block-start: .5rem;
        border-end-start-radius: var(--border-radius);
        border-end-end-radius: var(--border-radius);
        background-color: var(--c-white);
    }

    &__answer-text {
        @extend %html-format;
    }

    &__answer-image {
        max-width: 100%;
    }
}
