@import "src/styles";

.personality-test-progress {

    &__indicator {
        display: flex;
        align-items: center;
        gap: .125rem;
        width: 16rem;
    }

    &__outer-weight,
    &__center-weight,
    &__inner-weight,
    &__bar {
        border-radius: .125rem;
        border: 1px solid var(--c-white);
        background-color: rgba(var(--c-white-rgb), .5);
    }

    &__outer-weight {
        width: .25rem;
        height: 1.25rem;
    }

    &__center-weight {
        width: .375rem;
        height: 1.75rem;
    }

    &__inner-weight {
        width: .5rem;
        height: 2.25rem;
    }

    &__bar {
        width: 100%;
        padding: .125rem;
        border-radius: .5rem;
    }

    &__progress-wrapper {
        overflow: clip;
    }

    &__progress {
        width: 100%;
        height: .25rem;
        border-radius: .25rem;
        background-color: var(--c-orange);
        transition: translate .3s ease;
        translate: calc(var(--personality-test-progress) - 100%) 0;

        @include rtl {
            translate: calc(100% - var(--personality-test-progress)) 0;
        }
    }
}
