@import "src/styles";

.flavours-and-ingredients-header {
    position: relative;

    @include for-size(tablet-landscape-up) {
        padding-block-start: 5rem;
    }

    &__content-wrapper {
        position: relative;
        z-index: 20;
    }

    &__title {
        @extend %heading-2;

        position: relative;
        z-index: 20;
        max-width: 44rem;
        margin-block-start: 2rem;
        color: var(--c-blue);
    }
}
