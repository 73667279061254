@import "src/styles";

.vacancy-content-skeletons {

    &__back-button {
        width: 100%;
        max-width: 16rem;
    }

    &__title-wrapper {
        max-width: 44rem;
        margin-block-start: 4rem;
    }

    &__title {
        height: 3rem;

        & + & {
            margin-block-start: 1.5rem;
        }
    }

    &__feature-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-width: 44rem;
        margin-block-start: 2rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            gap: 2.5rem;
        }
    }

    &__feature-item {
        height: 2rem;
    }

    &__date-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        max-width: 40rem;
        margin-block-start: 2rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__date-label {
        width: 45%;
        height: 1rem;
    }

    &__apply-button {
        width: 12rem;
        height: 2.5rem;
        margin-block-start: 4rem;
    }

    &__body {
        margin-block-start: 4rem;
    }

    &__renderer {
        width: 100%;
        max-width: 46rem;
    }
}
