@import "src/styles";

$component: "article-overview";

.#{$component} {
    padding-block-start: var(--menu-height);
    overflow-x: clip;

    &__header {
        position: relative;
        z-index: 10;
    }

    &__title {
        @extend %heading-2;

        color: var(--c-orange);
    }

    &__description {
        margin-block-start: 2.5rem;
        max-width: 40rem;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        margin-block-start: 2rem;
        padding-inline-start: 0;
        list-style: none;

        @include for-size(tablet-portrait-up) {
            gap: 5rem;
        }
    }

    &__item {
        position: relative;

        &:nth-child(even) .#{$component}__item-wrapper {

            @include for-size(tablet-portrait-up) {
                flex-direction: row-reverse;
            }
        }
    }

    &__item-wrapper {
        display: flex;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__background {
        display: none;

        @include for-size(tablet-portrait-up) {
            display: block;
            position: absolute;
            inset-block-end: -119%;
            inset-inline-start: 35%;
            width: 100%;
            height: 320%;
        }

        @include rtl {

            @include for-size(tablet-portrait-up) {
                inset-inline-start: unset;
                inset-inline-end: 16%;
            }
        }
    }
}
