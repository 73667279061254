@import "src/styles";

.nutrition-table-us-row {
    position: relative;

    &--is-child {
        height: fit-content;
        line-height: 1.5;
        padding-block-start: .5rem;
        padding-inline-start: 1.5rem;
    }

    & + &::after {
        @extend %click-extend;

        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--c-black);
        opacity: .15;
    }

    &--is-child ~ &--is-child {
        padding-block-start: 0;

        &::after {
            display: none;
        }
    }

    &--is-last-child {
        padding-block-end: .5rem;
    }
}
