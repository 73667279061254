@import "src/styles";

$component: "flavour-header";

.#{$component} {
    --flavour-header-title-length: 1;
    --flavour-header-title-size: calc((100vw / var(--flavour-header-title-length)));
    --flavour-header-title-min-size: 2rem;
    --flavour-header-title-max-size: 6.5rem;

    position: relative;
    overflow-x: clip;

    @include for-size(tablet-portrait-up) {
        --flavour-header-title-min-size: 6.5rem;
        --flavour-header-title-max-size: 9.75rem;
    }

    @include for-size(tablet-landscape-up) {
        --flavour-header-title-size: calc((var(--wrapper-width) / var(--flavour-header-title-length)) * 1.5);
        --flavour-header-title-min-size: 7.5rem;
        --flavour-header-title-max-size: 16.25rem;
    }

    &__title-wrapper {
        @extend %flex-align-center;

        height: 40vh;
        padding-block: var(--menu-height) 2rem;

        @include for-size(tablet-portrait-up) {
            height: 75vh;
            padding-block: var(--menu-height) 5rem;
        }

        @include for-size(tablet-landscape-up) {
            height: 85vh;
        }

        @include for-size(desktop-up) {
            height: 90vh;
        }
    }

    &__title {
        @extend %heading-1;

        display: flex;
        flex-direction: column;
        width: fit-content;
        color: var(--c-white);
        font-size: clamp(var(--flavour-header-title-min-size), var(--flavour-header-title-size), var(--flavour-header-title-max-size));
        text-shadow: 0 2px .5rem rgba(var(--c-black-rgb), .08);

        @include for-size(tablet-portrait-up) {
            width: 100%;
        }

        &--is-single {
            align-items: center;

            .#{$component}__title-part,
            .#{$component}__title-part:nth-of-type(odd) {
                align-self: unset;
            }
        }

        &--is-triple {

            @include for-size(tablet-landscape-up) {
                --flavour-header-title-max-size: 9rem;
            }

            .#{$component}__title-part {
                align-self: flex-end;
                text-align: end;

                &:nth-child(2) {
                    padding-inline-end: 4vw;
                }

                &:nth-child(3) {
                    padding-inline-end: 8vw;
                }
            }
        }
    }

    &__title-part {
        width: fit-content;
        align-self: flex-start;
        hyphens: auto;

        &:nth-of-type(odd) {
            align-self: flex-end;
        }
    }

    &__description-wrapper {
        display: flex;
        margin-block-start: 2rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row-reverse;
            margin-block-start: 0;
        }
    }

    &__description {
        max-width: 36rem;
    }

    &__picture-wrapper {
        @extend %flex-align-center;
        @extend %click-extend;

        align-items: flex-start;
        z-index: -10;
    }

    &__picture {
        height: 100%;
        max-height: 60vh;

        @include for-size(tablet-portrait-up) {
            max-height: 75vh;
        }

        @include for-size(tablet-landscape-up) {
            max-height: 85vh;
        }

        @include for-size(desktop-up) {
            max-height: 90vh;
        }

        @include rtl {
            scale: -1 1;
        }
    }

    &__blob-wrapper {
        position: absolute;
        top: var(--menu-height);
        inset-inline-start: 0;
        z-index: -20;
        width: 110vw;
        max-height: 100vh;
        translate: -5vw 0;

        @include rtl {
            translate: 5vw 0;
            scale: -1 1;
        }

        @include for-size(tablet-portrait-up) {
            top: calc(var(--menu-height) - 10vh);
        }
    }

    &__blob {
        height: 100%;
        rotate: -15deg;
    }
}
