@import "src/styles";

.flavour-content {

    &__spotify-playlist-section {
        margin-block-start: 4rem;
    }

    &__ingredients-title-wrapper {
        --wrapper-width: 60rem;

        margin-block-start: 4rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 8rem;
        }
    }

    &__ingredients-title {
        @extend %heading-3;

        text-align: end;
        color: var(--c-pink);

        @include for-size(tablet-portrait-up) {
            text-align: start;
            padding-inline-start: 7.5rem;
        }

        @include for-size(tablet-landscape-up) {
            padding-inline-start: 0;
        }
    }

    &__ingredients-wrapper {
        margin-block-start: 2rem;
    }

    &__footer {
        margin-block-start: 4rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 10rem;
        }
    }
}
