@import "src/styles";

.contact-form {

    &__input {

        & + & {
            margin-block-start: 1.5rem;
        }
    }

    &__gym-owner-wrapper {
        margin-block: 1.5rem;
    }

    &__gym-owner-disclaimer {
        margin-block-end: 1.5rem;

        @include for-size(tablet-landscape-up) {
            max-width: 28rem;
        }
    }

    &__comment-field {
        min-height: 10em;
    }

    &__recaptcha-disclaimer,
    &__recaptcha-error {
        margin-block-start: 1rem;
    }

    &__submit-wrapper {
        margin-block-start: 4rem;

        @include for-size(tablet-landscape-up) {
            padding-inline-start: 2rem;
        }
    }

    &__submit-button {
        @extend %button--call-to-action;

        color: var(--c-accent);
    }

    &__submit-underline {
        @extend %label;

        margin-block-start: .5rem;
        letter-spacing: 1px;
        color: var(--c-grey);
    }
}
