@import "src/styles";

.hydration-station-section-background {
    position: absolute;
    inset-inline-start: -20%;
    width: 140%;
    height: 50vh;
    scale: 1 -1;

    @include for-size(tablet-portrait-up) {
        inset-inline-start: -10%;
        width: 120%;
    }

    @include for-size(tablet-landscape-up) {
        inset-inline-start: 0;
        width: 100%;
    }

    @include rtl {
        scale: -1 -1;
    }

    &__gradient-start {
        stop-color: var(--c-cyan);
    }

    &__gradient-end {
        stop-color: var(--c-sky);
    }

    &__svg-wave {
        stroke: var(--c-cyan);
        stroke-width: 1px;
    }
}
