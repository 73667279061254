@import "src/styles";

.navigation {
    --navigation-item-translation: 0 -.5rem;
    --navigation-item-opacity: 0;

    &--is-ready {
        --navigation-item-translation: 0 0;
        --navigation-item-opacity: 1;
    }

    &__main-item,
    &__social-navigation-wrapper,
    &__cookie-settings-wrapper {
        transition: translate .1s ease, opacity .1s ease;
        translate: var(--navigation-item-translation);
        opacity: var(--navigation-item-opacity);
    }

    &__main-list {
        padding: 0;
        text-align: end;
    }

    &__main-item {
        list-style-type: none;

        & + & {
            margin-block-start: .25rem;
        }
    }

    &__social-navigation {
        margin-block-start: 3rem;
    }

    &__social-list {
        justify-content: flex-end;
    }

    &__cookie-settings-wrapper {
        display: flex;
        flex-direction: row-reverse;
        margin-block-start: 2rem;
    }

    &__cookie-settings-button {
        @extend %button--no-background;

        padding: 0;
        font-size: .875rem;
    }
}
