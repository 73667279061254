@import "src/styles";

.prismic-page-header {
    position: relative;
    overflow-x: clip;

    &__text-wrapper {
        position: relative;
        z-index: 20;
    }

    &__title {
        @extend %heading-2;

        position: relative;
        z-index: 20;
        max-width: 44rem;
        margin-block-start: 2rem;
        hyphens: auto;

        &--colour-black { color: var(--c-black); }
        &--colour-red { color: var(--c-red); }
        &--colour-pink { color: var(--c-pink); }
        &--colour-blue { color: var(--c-blue); }
        &--colour-purple { color: var(--c-purple); }
        &--colour-yellow { color: var(--c-yellow); }
        &--colour-orange { color: var(--c-orange); }
        &--colour-cyan { color: var(--c-cyan); }
        &--colour-green { color: var(--c-green); }
    }

    &__intro {
        max-width: 32rem;
        margin-block-start: 2rem;
        font-size: 1rem;
    }

    &__call-to-action {
        @extend %button--call-to-action;

        margin-block-start: 4rem;
    }

    &__poster-wrapper {
        position: relative;
        inset-inline-start: 50%;
        z-index: 10;
        width: 125%;
        aspect-ratio: 1;
        margin-block: 2rem 1rem;
        clip-path: url(#prismic-page-header-clip);
        translate: -50% 0;
        background-color: var(--c-cyan);

        @include rtl {
            scale: -1 1;
            translate: 50% 0;
        }

        @include for-size(tablet-portrait-up) {
            position: absolute;
            inset-block-end: 50%;
            inset-inline-start: 75%;
            width: 65%;
            margin-block: unset;
        }

        @include for-size(tablet-landscape-up) {
            inset-block-start: 50%;
            inset-inline-start: 50%;
            width: auto;
            height: 50rem;
            translate: 0 -50%;

            @include rtl {
                translate: 0 -50%;
            }
        }

        &--droplet-1 {
            margin-block: -5rem;

            @include for-size(tablet-portrait-up) {
                margin-block: unset;
            }

            @include for-size(tablet-landscape-up) {
                inset-inline-start: 55%;
            }
        }

        &--droplet-2 {

            @include for-size(tablet-landscape-up) {
                inset-inline-start: 50%;
            }
        }

        &--droplet-3 {
            margin-block: -2rem;

            @include for-size(tablet-portrait-up) {
                margin-block: unset;
            }

            @include for-size(tablet-landscape-up) {
                inset-inline-start: 55%;
            }
        }

        &--droplet-4 {
            inset-inline-start: 25%;

            @include for-size(tablet-portrait-up) {
                inset-block-start: -10%;
                inset-inline-start: 75%;
            }

            @include for-size(tablet-landscape-up) {
                inset-block-start: 50%;
                inset-inline-start: 40%;
            }
        }
    }

    &__poster {
        @extend %click-extend;

        object-fit: cover;

        @include rtl {
            scale: -1 1;
        }
    }

    &__poster-clip {
        display: block;
        max-width: 100%;
    }
}
