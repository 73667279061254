@import "src/styles";

.centered-text-slice {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__paragraph {
        max-width: 28rem;
        margin-block-start: 4rem;
        line-height: 2;
    }
}
