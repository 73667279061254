%printable-page {

    @media print {
        -webkit-print-color-adjust: economy;
        print-color-adjust: economy;
        padding-top: 2rem !important;

        &::after {
            content: "Visit https://yangasportswater.com/ for the most recent version of this document.";
            display: block;
            margin-top: 2em;
        }

        @page {
            margin: 1.8cm 2cm;
        }

        @at-root {
            .menu,
            .cookie-settings,
            .footer {
                display: none;
            }
        }

        * {
            margin: 0;
            color: var(--c-black);

            & + * {
                margin-top: 1.2em;
            }
        }

        a {
            text-decoration: none;

            &::after {
                content: " (" attr(href) ") ";
            }

            &[href^="mailto"]::after {
                content: unset;
            }
        }
    }
}
