@import "src/styles";

.faq {

    &__title {
        @extend %heading-2;

        color: var(--c-purple);
    }

    &__intro {
        @extend %html-format;

        max-width: 46rem;
        margin-block-start: 1.5rem;
    }

    &__slice-renderer {
        margin-block-start: 2rem;
    }
}
