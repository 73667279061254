@import "src/styles";

.vacancy-footer {

    &__date-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__date-label {
        font-size: .875rem;
    }

    &__apply-button {
        margin-block-start: 2rem;

        @include for-size(tablet-landscape-up) {
            margin-block-start: 4rem;
        }
    }
}
