@import "src/styles";

.error-label {
    @extend %label;

    color: var(--c-negative);

    &:first-letter {
        text-transform: uppercase;
    }
}
