@import "src/styles";

.not-found-page {
    margin-block-end: 2.5rem;
    padding-block: 0;

    @include for-size(tablet-portrait-up) {
        margin-block-end: 0;
    }
}
