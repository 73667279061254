@import "src/styles";

.ingredients {

    &__caffeine-warning {
        margin-block-end: 1.5rem;
    }

    &__control-wrapper {
        display: flex;
        gap: 2rem;
        width: 100%;
        max-width: 58rem;
        margin-block-end: 2rem;

        @include for-size(phone-only) {
            flex-direction: column;
        }
    }

    &__control-switch {
        flex-grow: 1;
        max-width: 28rem;
    }

    &__control-switch-label {
        justify-content: center;
    }

    &__table-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: flex-start;
        }
    }

    &__table {
        width: 100%;
        height: fit-content;

        @include for-size(tablet-portrait-up) {
            width: 55%;
        }
    }

    &__ingredients-card {
        width: 100%;
        border-radius: var(--border-radius-large);
        padding: 2rem;
        padding-block-start: 2.75rem;
        font-size: .875rem;
        background-color: var(--c-white);

        @include for-size(tablet-portrait-up) {
            width: 45%;
        }
    }

    &__ingredients-title {
        margin: 0;
        font-size: 1rem;
        font-weight: var(--fw-medium);
        color: var(--c-purple);
    }

    &__ingredients {
        margin-block-start: 1rem;
    }

    &__notice,
    &__intensity-notice {
        margin-block-start: 1rem;
        font-style: italic;
    }

    &__caffeine-notice {
        margin-block-start: 1rem;
    }

    &__footnote,
    &__table-footnote {
        margin-block-start: 1rem;
        line-height: 1.75;
        font-weight: var(--fw-regular);
        color: var(--c-black);
        opacity: .72;
    }
}
