@import "src/styles";

.scroll-to-top {
    display: flex;
    position: relative;
    overflow: clip;

    &__button {
        @extend %button--no-background;

        position: relative;
        z-index: 20;
        width: 100%;
        padding-block: 1rem;
        font-size: .75rem;
        color: var(--c-black);
        flex-direction: column;
    }

    &__icon {
        height: .375rem;
        color: var(--c-accent);
    }

    &__svg {
        position: absolute;
        inset-block-end: -.625rem;
        inset-inline-start: 50%;
        width: 100%;
        min-width: 32rem;
        height: 4rem;
        fill: var(--c-white);
        translate: -50% 0;

        @include rtl {
            translate: 50% 0;
        }
    }
}
