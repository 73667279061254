@import "src/styles";

$component: "personality-test";

.#{$component} {
    position: relative;
    width: 100%;
    overflow-x: clip;

    &--is-starting {

        .#{$component}__intro-slide {
            translate: -2rem 0;
            opacity: 0;

            @include rtl {
                translate: 2rem 0;
            }
        }

        .#{$component}__form {
            display: block;
        }
    }

    &--has-started {

        .#{$component}__wrapper {
            padding-block-end: 16rem;

            @include for-size(tablet-portrait-up) {
                padding-block-end: unset;
            }
        }

        .#{$component}__intro-slide {
            display: none;
        }

        .#{$component}__form {
            pointer-events: all;
            translate: 0 0;
            opacity: 1;

            // Required to match specificity
            @include rtl {
                translate: 0 0;
            }
        }
    }

    &--is-ending {

        .#{$component}__form {
            pointer-events: none;
            opacity: 0;
        }
    }

    &--is-transitioning {

        .#{$component}__question-slide {
            animation: personality-question-fade var(--personality-test-transition-duration) ease;
        }
    }

    &--moving-forwards {
        --fade-offset-start: -2rem;
        --fade-offset-end: 2rem;

        @include rtl {
            --fade-offset-start: 2rem;
            --fade-offset-end: -2rem;
        }
    }

    &--moving-backwards {
        --fade-offset-start: 2rem;
        --fade-offset-end: -2rem;

        @include rtl {
            --fade-offset-start: -2rem;
            --fade-offset-end: 2rem;
        }
    }

    &__wrapper {
        position: relative;
        z-index: 10;
    }

    &__intro-slide,
    &__form {
        transition: translate var(--personality-test-half-transition-duration) ease, opacity var(--personality-test-half-transition-duration) ease;
    }

    &__intro-slide {
        position: absolute;
        translate: 0 0;
        opacity: 1;
    }

    &__form {
        translate: 2rem 0;
        opacity: 0;
        pointer-events: none;

        @include rtl {
            translate: -2rem 0;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        max-width: 24rem;
        margin-block-start: 2rem;

        @include for-size(tablet-portrait-up) {
            gap: 4rem;
            margin-block-start: 3rem;
        }
    }

    &__progress-indicator {
        align-self: center;
    }

    &__next-button {
        @extend %button--call-to-action;
    }

    &__background {
        position: absolute;
        inset-block-end: 0;
        inset-inline-start: 0;
        z-index: 0;
    }
}
