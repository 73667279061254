.logo {
    min-height: 2.5rem;

    &__christmas-hat {
        --c-xmas-fabric: #fd1300;
        --c-xmas-fabric-highlight: #fe431e;
        --c-xmas-fabric-shade: #da0f00;
        --c-xmas-fluff: #fff;
        --c-xmas-fluff-shade: #fef2f2;
        --c-xmas-fluff-shade-dark: #ffd5df;
        --c-xmas-shade: #bf0606;

        &-fabric {
            fill: var(--c-xmas-fabric);
        }

        &-fabric-shade {
            fill: var(--c-xmas-fabric-shade);
        }

        &-fabric-highlight {
            fill: var(--c-xmas-fabric-highlight);
        }

        &-fluff {
            fill: var(--c-xmas-fluff);
        }

        &-fluff-shade {
            fill: var(--c-xmas-fluff-shade);
        }

        &-fluff-shade-dark {
            fill: var(--c-xmas-fluff-shade-dark);
        }

        &-shade {
            fill: var(--c-xmas-shade);
        }
    }
}
