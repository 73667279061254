@import "src/styles";

.related-articles {

    &__title {
        @extend %heading-3;

        color: var(--c-orange);
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        gap: 1.5rem;
        margin-block-start: 3.5rem;

        @include for-size(tablet-portrait-up) {
            flex-wrap: nowrap;
        }
    }

    &__item {
        width: 100%;
        list-style-type: none;

        @include for-size(tablet-portrait-up) {
            max-width: calc((100% - 3rem) / 3);
        }
    }
}
