@import "src/styles";

.personality-test-question-slide {

    &__question {
        @extend %heading;

        font-size: 2.25rem;
        color: var(--c-green);

        @include for-size(tablet-portrait-up) {
            font-size: 5rem;
        }
    }

    &__answer-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-block-start: 3rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 5rem;
        }
    }

    &__answer-item {
        list-style-type: none;
    }

    &__answer-button {
        width: 100%;
        max-width: 28rem;
        line-height: 1.5;
        color: var(--c-black);
        border-radius: 4rem;
        border: 1px solid var(--c-white);
        background-color: rgba(var(--c-white-rgb), .5);

        &--is-selected {
            color: var(--c-white);
            border-color: var(--c-primary);
            background-color: var(--c-primary);
        }
    }
}
