.welcome-section-background {

    &__gradient-start {
        stop-color: var(--c-cyan);
    }

    &__gradient-end {
        stop-color: var(--c-sky);
    }

    &__svg-wave {
        stroke: var(--c-cyan);
        stroke-width: 1px;
    }
}
