@import "src/styles";

.image-grid-slice {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        justify-content: space-between;
    }

    &__image-wrapper {
        position: relative;
        width: 100%;
        min-height: 20rem;

        &--inline-end {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }

    &__image {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
