@import "src/styles";

.featured-image-slice {
    --featured-image-rotation: 5deg;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;

    @include for-size(tablet-landscape-up) {
        --featured-image-rotation: 7.5deg;

        flex-direction: row;
        gap: 7.5rem;
    }

    &--is-mirrored {

        @include for-size(tablet-landscape-up) {
            --featured-image-rotation: -7.5deg;

            flex-direction: row-reverse;
        }
    }

    &--is-mirrored-mobile {
        --featured-image-rotation: -5deg;

        flex-direction: column-reverse;

        @include for-size(tablet-landscape-up) {
            --featured-image-rotation: 7.5deg;

            flex-direction: row;
        }
    }

    &--is-mirrored#{&}--is-mirrored-mobile {

        @include for-size(tablet-landscape-up) {
            --featured-image-rotation: -7.5deg;

            flex-direction: row-reverse;
        }
    }

    &__image-wrapper,
    &__text-wrapper {
        width: 100%;
    }

    &__image-wrapper {
        @extend %flex-align-center;
    }

    &__image {
        width: 75vw;
        border-radius: var(--border-radius);
        border: .5rem solid var(--c-white);
        box-shadow: var(--box-shadow);
        rotate: var(--featured-image-rotation);

        @include for-size(tablet-landscape-up) {
            width: 100%;
        }
    }

    &__paragraph {
        margin-block-start: 4rem;
    }

    &__call-to-action {
        @extend %button--call-to-action;

        margin-block-start: 2.5rem;
    }
}
