@import "src/styles";

.flavours-and-ingredients {
    padding-block-start: var(--menu-height);
    overflow-x: hidden;

    &__header {
        position: relative;
        z-index: 10;
    }

    &__intro-wrapper {
        position: relative;
        z-index: 20;
        margin-block-start: 2rem;
    }

    &__intro {

        @include for-size(tablet-portrait-up) {
            padding-inline-end: 60%;
        }

        @include for-size(tablet-landscape-up) {
            padding-inline-end: 40%;
        }
    }

    &__carousel {
        position: relative;
        z-index: 20;
        margin-block-start: 2rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 5rem;
        }
    }

    &__nutrition-facts-wrapper {
        margin-block-start: 4rem;
    }

    &__nutrition-title {
        @extend %heading-2;

        color: var(--c-orange);
    }

    &__nutrition-subtitle {
        @extend %heading-4;

        margin-block-start: 1.5rem;
    }

    &__ingredients-wrapper {
        margin-block-start: 2rem;
    }

    &__ingredients-selector {
        width: 14rem;
    }

    &__ingredients {
        margin-block-start: 2.5rem;
    }
}
