$component: "liquid-button";

.#{$component} {
    display: inline-flex;
    border-radius: 2rem;

    &__button {
        display: flex;
        align-items: center;
        gap: .625rem;
        position: relative;
        overflow: clip;

        &:focus,
        &:hover {
            animation: fluid-scale .5s ease-in-out;

            .#{$component}__fill {
                scale: 1;
            }
        }

        &--align-right {
            flex-direction: row-reverse;
        }

        &--is-disabled {
            pointer-events: none;
            opacity: .5;

            &:focus,
            &:hover {
                animation: none;

                .#{$component}__fill {
                    scale: 0;
                }
            }
        }
    }

    &__fill {
        position: absolute;
        top: var(--liquid-button-y-pos);
        left: var(--liquid-button-x-pos);
        z-index: 10;
        width: 200%;
        aspect-ratio: 1;
        pointer-events: none;
        border-radius: 100%;
        background-color: var(--c-white);
        opacity: .5;
        transition: scale .3s ease;
        will-change: scale;
        transform: translate(-50%, -50%);
        transform-origin: top left;
        scale: 0;
    }

    &__icon {
        position: relative;
        z-index: 20;
        height: 1em;
    }

    &__label {
        position: relative;
        z-index: 20;
        transform: translateZ(0);
    }
}
