@import "src/styles";

.article-header {
    position: relative;
    overflow-x: clip;

    &__content-wrapper {
        position: relative;
        z-index: 20;
    }

    &__category-list {
        display: flex;
        gap: 1rem;
    }

    &__category-item {
        @extend %label;

        padding-block: 1rem;
        padding-inline: 2rem;
        list-style-type: none;
        border-radius: var(--border-radius);
        background-color: var(--c-white);
        box-shadow: var(--box-shadow);
    }

    &__title {
        @extend %heading-2;

        position: relative;
        z-index: 20;
        max-width: 44rem;
        margin-block-start: 2rem;
        hyphens: auto;

        &--colour-black { color: var(--c-black); }
        &--colour-red { color: var(--c-red); }
        &--colour-pink { color: var(--c-pink); }
        &--colour-blue { color: var(--c-blue); }
        &--colour-purple { color: var(--c-purple); }
        &--colour-yellow { color: var(--c-yellow); }
        &--colour-orange { color: var(--c-orange); }
        &--colour-cyan { color: var(--c-cyan); }
        &--colour-green { color: var(--c-green); }
    }

    &__meta-wrapper {
        margin-block-start: 2rem;
        font-size: 1rem;
    }

    &__read-time,
    &__created-date {
        font-weight: var(--fw-medium);
    }
}
