@import "src/styles";

.personality-test-result-share {

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__label {
        @extend %label;
    }

    &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1.5rem;
    }

    &__item {
        list-style-type: none;
    }

    &__button {
        @extend %flex-align-center;
        @include circle(2.5rem);

        padding: 0;
        transition: scale .1s ease;
        scale: 1;

        &:hover,
        &:focus {
            scale: 1.1;
        }
    }

    &__icon {
        height: 1.125rem;
    }
}
