@import "src/styles";

.page-preview-slice {

    &__description {
        @extend %html-format;

        max-width: 48rem;
        margin-block-start: 1.5rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 2rem;
        }

        h2 {
            @extend %heading-3;

            & + * {
                margin-block-start: 1.5rem;
            }
        }

        h3 {
            @extend %heading-4;

            & + * {
                margin-block-start: 1rem;
            }
        }
    }

    &__call-to-action {
        @extend %button--call-to-action;

        margin-block-start: 2rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 4rem;
        }
    }
}
