@import "src/styles";

.nutrition-table-eu-row {

    &--is-header {
        border-block-start: 1px solid var(--c-grey);
    }

    &--is-last-header {
        border-block-end: 1px solid var(--c-grey);
    }
}
