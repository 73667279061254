@import "src/styles";

.skeleton {
    @extend %skeleton-fade;

    --skeleton-width: 100%;

    display: block;
    width: var(--skeleton-width);
    height: 1.5em;
    border-radius: var(--border-radius-large);
    background-color: var(--c-cyan);
}
