@import "src/styles";

.spotify-playlist-section {
    --wrapper-width: 60rem;
    --c-spotify: #1db954;

    position: relative;
    overflow-x: clip;

    &__wrapper {
        position: relative;
    }

    &__title {
        @extend %heading-3;

        color: var(--c-pink);
    }

    &__widget-wrapper {
        display: flex;
        flex-direction: column;
        margin-block-start: 3.5rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            gap: 2.5rem;
        }
    }

    &__whole-picture-wrapper {
        position: absolute;
        inset-block-start: 50%;
        z-index: -10;
        translate: calc(-100% + 7rem) -10%;

        @include rtl {
            translate: calc(100% - 7rem) -10%;
        }

        @include for-size(tablet-landscape-up) {
            translate: calc(-100% - 2.5rem) -50%;

            @include rtl {
                translate: calc(100% + 2.5rem) -50%;
            }
        }

        @include for-size(desktop-up) {
            translate: calc(-100% - 4.5rem) -50%;

            @include rtl {
                translate: calc(100% + 2.5rem) -50%;
            }
        }
    }

    &__whole-picture {
        width: 40rem;

        @include rtl {
            scale: -1 1;
        }
    }

    &__playlist-picture-wrapper {
        display: flex;
        align-items: flex-start;
        gap: 1.5rem;
    }

    &__playlist-picture {
        width: 7.5rem;
        aspect-ratio: 1;
        overflow: clip;
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow);

        @include for-size(tablet-portrait-up) {
            width: 12.5rem;
        }
    }

    &__description {
        display: none;
        visibility: hidden;
        max-width: 24rem;
        min-height: 6rem;
        flex: 1;

        @include for-size(tablet-portrait-up) {
            display: block;
            visibility: visible;
            padding-block-start: 1rem;
        }

        &--is-mobile {
            display: block;
            visibility: visible;

            @include for-size(tablet-portrait-up) {
                display: none;
                visibility: hidden;
                padding-block-start: unset;
            }
        }
    }

    &__button-wrapper {
        display: flex;
        gap: 1rem;
        margin-block-start: -.5rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 2rem;
            translate: -3.5rem 0;

            @include rtl {
                translate: 3.5rem 0;
            }
        }
    }

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(4rem);

        background-color: var(--c-white);
        box-shadow: var(--box-shadow);
    }

    &__icon {
        height: 2rem;
        color: var(--c-spotify);
    }

    &__link-button {
        @extend %button--call-to-action;
    }

    &__chunk-picture-wrapper {
        align-self: flex-end;
        inset-block-end: 0;
        inset-inline-end: 0;

        @include for-size(tablet-portrait-up) {
            position: absolute;
            translate: -50% 50%;

            @include rtl {
                translate: 50% 50%;
            }
        }

        @include for-size(tablet-landscape-up) {
            position: static;
            translate: 0 0;

            @include rtl {
                translate: 0 0;
            }
        }
    }

    &__chunk-picture {
        width: 12rem;

        @include rtl {
            scale: -1 1;
        }

        @include for-size(tablet-portrait-up) {
            position: static;
        }

        @include for-size(desktop-up) {
            width: 18rem;
        }
    }

    &__widget-blob-wrapper {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        z-index: -20;
        translate: -2rem 25%;

        @include rtl {
            translate: 2rem 25%;
        }

        @include for-size(tablet-portrait-up) {
            translate: -1% 10%;

            @include rtl {
                translate: 1% 10%;
            }
        }
    }

    &__widget-blob {
        display: block;
        position: relative;
        width: 28rem;
        height: 14rem;

        @include for-size(tablet-portrait-up) {
            width: 46rem;
            height: 22rem;
        }
    }

    &__blob-wrapper {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        z-index: -30;
        width: 120vw;
        max-height: 100vh;
        translate: -10vw 0;

        @include rtl {
            scale: -1 1;
        }

        @include for-size(tablet-portrait-up) {
            top: -12.5rem;
            width: 80vw;
            translate: -5vw 0;
        }
    }

    &__blob {
        height: 100%;
    }
}
