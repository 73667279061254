@import "src/styles";

.video-slice {
    position: relative;

    &--is-full-width {
        width: auto;
        max-width: unset;
        padding-inline: unset;
    }

    &:hover &__play-button,
    &:focus-within &__play-button {
        opacity: .5;

        &:hover,
        &:focus {
            opacity: 1;
        }
    }

    &__play-button-wrapper {
        @extend %flex-align-center;
        @extend %click-extend;
    }

    &__play-button {
        @extend %button--no-background;

        position: relative;
        z-index: 20;
        padding: 0;
        opacity: 0;
        transition: opacity .3s ease;
    }

    &__play-icon {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        height: 2.5rem;
        color: var(--c-white);
        translate: -50% -50%;
    }

    &__play-blob {
        width: 8.75rem;
    }

    &__play-blob-path {
        fill: var(--c-pink);
    }

    &__video {
        position: relative;
        z-index: 10;
        width: 100%;
        max-height: 90vh;
        object-fit: contain;
    }
}
