@import "src/styles";

.header-droplet-image {
    position: relative;
    inset-inline-start: 50%;
    z-index: 10;
    width: 125%;
    aspect-ratio: 1;
    margin-block: 2rem 1rem;
    clip-path: url(#header-droplet-image-clip);
    translate: -50% 0;
    background-color: var(--c-cyan);

    @include rtl {
        scale: -1 1;
        translate: 50% 0;
    }

    @include for-size(tablet-portrait-up) {
        position: absolute;
        inset-block-start: -25%;
        inset-inline-start: 75%;
        width: 75%;
        margin-block: unset;
    }

    @include for-size(tablet-landscape-up) {
        inset-block-start: 50%;
        inset-inline-start: 50%;
        width: auto;
        height: 50rem;
        translate: 0 -50%;

        @include rtl {
            translate: 0 -50%;
        }
    }

    &--droplet-1 {
        margin-block: -5rem;

        @include for-size(tablet-portrait-up) {
            margin-block: unset;
        }

        @include for-size(tablet-landscape-up) {
            inset-inline-start: 55%;
        }
    }

    &--droplet-2 {

        @include for-size(tablet-landscape-up) {
            inset-inline-start: 50%;
        }
    }

    &--droplet-3 {
        margin-block: -2rem;

        @include for-size(tablet-portrait-up) {
            margin-block: unset;
        }

        @include for-size(tablet-landscape-up) {
            inset-inline-start: 55%;
        }
    }

    &--droplet-4 {
        inset-inline-start: 25%;

        @include for-size(tablet-portrait-up) {
            inset-block-start: -10%;
            inset-inline-start: 75%;
        }

        @include for-size(tablet-landscape-up) {
            inset-block-start: 50%;
            inset-inline-start: 40%;
        }
    }

    &__poster {
        @extend %click-extend;

        width: 100%;
        height: 100%;

        @include rtl {
            scale: -1 1;
        }
    }

    &__poster-image {
        object-fit: cover;
    }

    &__poster-clip {
        display: block;
        max-width: 100%;
    }
}
